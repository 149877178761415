<div [style.visibility]="showPage ? 'visible' : 'hidden'">
    <o-navbar
        [navItems]="navbarMenu"
        [hideInRoutes]="hideInRoutes"></o-navbar>
    <router-outlet></router-outlet>
    <o-footer
        [footerItems]="footerItems"
        [copyItemsLeft]="footerCopyItemsLeft"
        [copyItemsRight]="footerCopyItemsRight"
        colClass="col_4"></o-footer>
</div>
