import { VideoOptions } from './o-scalio-video.model';
import { ViewportScroller } from '@angular/common';
import { PlatformService } from '../../../../@shared/utils/platform/platform.service';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    ViewEncapsulation,
    AfterViewInit,
    Inject,
} from '@angular/core';

declare var videojs: any;

@Component({
    selector: 'o-scalio-video-section',
    templateUrl: './o-scalio-video-section.component.html',
    styleUrls: ['./o-scalio-video-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScalioVideoSectionComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('target') target!: ElementRef;
    @Input() video!: VideoOptions;
    @Input() overlay: boolean = false;
    @Input() hasVideo: boolean = false;
    @Input() isPlaying: boolean = false;
    @Input() hasVideoControls: boolean = false;
    @Input() title!: string;
    @Input() content!: string;
    @Input() btnLabel!: string;
    @Input() btnLink!: string;
    @Input() btnClassName: string = '';
    @Input() btnVideoClassName: string = '';
    @Input() btnSecondaryLabel!: string;
    @Input() btnSecondaryClassName!: string;
    @Input() hasLine!: boolean;
    @Input() containerClass?: string;

    player: any;

    constructor(private platform: PlatformService, private scroller: ViewportScroller) {}

    ngOnInit(): void {}

    goDown() {
        if (this.platform.isBrowser) {
            document.getElementById('nextSection')?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.platform.isBrowser)
            this.player = videojs(this.target.nativeElement, this.video, () => this.onPlayerReady());
    }

    onPlayerReady() {
        this.player.on('ended', () => {
            this.isPlaying = false;
        });
        if (this.isPlaying) {
        }
    }
    onPlayPause(event: any) {
        if (this.isPlaying) {
            this.player.pause();
        } else {
            this.player.play();
        }
        this.isPlaying = !this.isPlaying;
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
