import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NavigationModel, SubMenuNavigationModel } from './m-navbar-item.model';
import { AuthenticationService } from '../../../cms/auth/authentication.service';

@Component({
    selector: 'm-navigation',
    templateUrl: './m-navbar-item.component.html',
    styleUrls: ['./m-navbar-item.component.scss'],
})
export class NavbarItemComponent implements OnInit, OnChanges {
    @Input() navlinks!: Array<NavigationModel>;
    @Input() footerLinks!: Array<NavigationModel>;
    links!: Array<NavigationModel>;
    @Input() hasBorder!: boolean;
    @Input() hideMobile!: boolean;
    @Input() hideMenu: boolean = false;
    @Input() containerClass?: string;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();

    constructor(
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.navlinks) this.links = this.navlinks.filter((item) => !item.isBtn);
    }

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }

    click(item: NavigationModel, submenu?: SubMenuNavigationModel) {
        item.isOpen = false;
        if (item.logOut || (submenu && submenu.logOut)) {
            this.authenticationService.logout();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigate(['/'], { replaceUrl: true });
        }
        this.closeModal.emit();
    }
}
