import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ApiService } from './api-service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
    providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
    constructor(private service: ApiService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApi = ['api', 'papi'];
        let getUserDetails = this.service.getStorage();
        let url = request.url;
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (!/^(http|https):/i.test(request.url) && url.includes('assets')) {
            url = environment.baseUrl + request.url;
        }

        if (isApi.find((x) => url.includes(x))) {
            if (!/^(http|https):/i.test(request.url)) {
                url = environment.serverUrl + request.url;
            }

            if (/^(http|https):/i.test(url)) {
                if (!!Object.keys(getUserDetails))
                    headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        site: !!Object.keys(getUserDetails) ? getUserDetails.session.site : '',
                        Authorization: !!Object.keys(getUserDetails) ? getUserDetails.id_token : '',
                    });
            }
        }

        request = request.clone({
            url,
            headers,
        });

        return next.handle(request);
    }
}
