import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TabsComponent } from './m-tabs.component';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { NoDataContentModule } from '../m-no-data-content/m-no-data-content.module';

@NgModule({
    declarations: [TabsComponent],
    imports: [CommonModule, TranslateModule, ImageModule, NoDataContentModule],
    exports: [TabsComponent],
})
export class TabsModule {}
