import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FooterItemModel } from './m-footer-item.model';

@Component({
    selector: 'm-footer-item',
    templateUrl: './m-footer-item.component.html',
    styleUrls: ['./m-footer-item.component.scss'],
})
export class FooterItemComponent implements OnInit {
    @Input() footerLinks!: Array<FooterItemModel>;
    @Input() hasBorder!: boolean;
    @Input() hideMobile!: boolean;
    @Input() containerClass?: string;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {}

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }
}
