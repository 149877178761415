import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScalioVideoSectionComponent } from './o-scalio-video-section.component';
import { HeroSectionItemModule } from '../../../../@shared/atomic/molecules/m-hero-section-item/m-hero.section-item.module';

@NgModule({
    declarations: [ScalioVideoSectionComponent],
    imports: [CommonModule, TranslateModule, HeroSectionItemModule, AngularSvgIconModule],
    exports: [ScalioVideoSectionComponent],
})
export class ScalioVideoSectionModule {}
