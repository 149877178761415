import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { SeoMeta } from '../../cms/models/seo.model';
import { AppSettings } from '@src/app/app.settings';
import { PlatformService } from '../platform/platform.service';
import { HttpStateClient } from '../../http/http-client-state';
import * as data from 'src/assets/data/seo.json';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    appDesc: string = '';
    appName: string = '';
    pages: SEOSettingPage[] = [];
    page?: SEOSettingPage;
    urlImageBase: string = '';
    seoObj?: SeoMeta;
    constructor(
        private title: Title,
        private meta: Meta,
        private location: Location,
        private http: HttpClient,
        private platform: PlatformService,
        private httpState: HttpStateClient,
        @Inject(DOCUMENT) private dom: any,
    ) {}

    addSeoSetting(path: string): void {
        const body = data as any;
        this.appDesc = body.appDesc;
        this.appName = body.appName;
        this.urlImageBase = body.imageUrl;
        this.pages = body.pages;
        this.page = this.pages.find((x) => x.path === path.replace('/', ''));
        if (this.page) {
            this.seoObj = {
                title: this.page.title,
                ogTitle: this.page.ogTitle,
                ogDescription: this.page.ogDescription,
                description: this.page.description,
                imageUrl: this.page.imageUrl.length > 0 ? this.page.imageUrl : this.urlImageBase,
                slug: '',
            };

            this.addSeoMeta(this.seoObj);
        }
    }

    updateCanonicalUrl(url: string) {
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        } else {
            element.setAttribute('rel', 'canonical');
            element.setAttribute('href', url);
        }
    }

    private addTagsImage(image?: string) {
        this.addMetaTag('og:image', image ?? '', true);
        this.addMetaTag('twitter:image', image ?? '');
    }

    addTitle(title: string) {
        this.title.setTitle(title);
    }

    addSeoMeta(meta: SeoMeta) {
        this.httpState.saveStateByKey('state-seo-meta', meta);
        if (this.platform.isServer) return;
        this.addTagsBase(
            meta.title,
            meta.description,
            meta.ogTitle ?? meta.title,
            meta.ogDescription ?? meta.description,
            meta.type,
        );
        this.addTagsImage(meta.imageUrl);
    }

    private addTagsBase(title: string, description?: string, ogTitle?: string, ogDescription?: string, type?: string) {
        if (this.platform.isServer) return;
        this.meta.removeTag('property = "og:image"');
        this.meta.removeTag('name = "twitter:image"');
        const _title = title && title.length > 0 ? title : this.appName;
        const _desc = description && description.length > 0 ? description : this.appDesc;
        const _ogTitle = ogTitle && ogTitle.length > 0 ? ogTitle : _title;
        const _ogDescription = ogDescription && ogDescription.length > 0 ? ogDescription : _desc;
        this.title.setTitle(_title);
        this.addMetaTag('description', _desc);
        this.addMetaTag('og:title', _ogTitle, true);
        this.addMetaTag('og:description', _ogDescription, true);
        this.addMetaTag('og:type', type ?? 'website', true);
        const url = environment.baseUrl + this.location.prepareExternalUrl(this.location.path());
        this.addMetaTag('og:url', url, true);
        this.addMetaTag('twitter:title', _ogTitle);
        this.addMetaTag('twitter:description', _ogDescription);
        this.updateCanonicalUrl(url);
    }

    private addMetaTag(key: string, value: string, isProperty: boolean = false) {
        if (this.platform.isServer) return;
        const keyType = isProperty ? 'property' : 'name';
        let metaEl: HTMLMetaElement | null = this.meta.getTag(`${keyType}= "${key}"`);
        if (metaEl) {
            if (isProperty) this.meta.updateTag({ property: key, content: value });
            else this.meta.updateTag({ name: key, content: value });
        } else {
            if (isProperty) this.meta.addTag({ property: key, content: value });
            else this.meta.addTag({ name: key, content: value });
        }
    }
}

export interface SEOSetting {
    appDesc: string;
    appName: string;
    imageUrl: string;
    pages: SEOSettingPage[];
}

export interface SEOSettingPage {
    path: string;
    title: string;
    ogTitle?: string;
    imageUrl: string;
    description: string;
    ogDescription?: string;
}
