import { Type, Input, Component } from '@angular/core';

//TODO: This can be used in the generator.

export class GenerationItem {
    constructor(
        public selector: string,
        public component: Type<any>,
        public data: any,
        public parentSelector: string = '',
        public children: GenerationItem[] = [],
    ) {}
}

@Component({
    template: '',
})
export abstract class GenerationComponent {
    @Input() set data(data: any) {
        if (data) {
            var props = Object.keys(data);
            props.forEach((prop) => {
                this[prop] = data[prop];
            });
        }
    }
}
