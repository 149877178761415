<ng-container>
    <p
        [class.light-color]="contrast"
        [class.bold-weight]="bold"
        [class.small-size]="small"
        [class.medium-size]="medium"
        [class.color-gray]="grayColor"
        [class.color-validation-error]="errorColor"
        [class.color]="brandColor"
        [class.mb-0]="noBottom"
        [class.text-center]="textCenter"
        class="a-paragraph"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="content"></p>
</ng-container>
