<div class="a-image">
    <!-- Background Image -->
    <div
        *ngIf="bgImageConfig"
        title="{{ alt }}"
        class="background"
        [class.background-img-section]="fixedSize"
        [class.full-height]="fullHeight"
        [class.card-height]="cardHeight"
        [class.grey-scale]="greyScale"
        [class.background-right-position-mobile]="bgRightPositioMobile"
        [class.overlay]="overlay"
        [ngClass]="[containerClass ? containerClass : '']"
        [ngStyle]="{ 'background-color': 'transparent' }"
        [lazyLoad]="webpUrl"
        [offset]="1000">
        <ng-content></ng-content>
    </div>

    <!-- Image -->
    <picture *ngIf="imageConfig">
        <source
            [srcset]="webpUrl"
            type="image/webp" />
        <source [srcset]="imageConfig" />
        <img
            *ngIf="height && width"
            src="{{ imageConfig }}"
            [ngClass]="{ 'img-fluid': true, 'grey-scale': greyScale, 'medium-image': mediumImage }"
            alt="{{ alt }}"
            title="{{ alt }}"
            height="{{ height }}"
            width="{{ width }}"
            loading="lazy" />
        <img
            *ngIf="!height || !width"
            src="{{ imageConfig }}"
            [ngClass]="{ 'img-fluid': true, 'grey-scale': greyScale, 'medium-image': mediumImage }"
            alt="{{ alt }}"
            title="{{ alt }}"
            loading="lazy" />
    </picture>
</div>
