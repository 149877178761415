import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from './m-avatar.component';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    declarations: [AvatarComponent],
    imports: [CommonModule, TranslateModule, ParagraphModule, LazyLoadImageModule],
    exports: [AvatarComponent],
})
export class AvatarModule {}
