import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarItemComponent } from './m-navbar-item.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguageSelectorModule } from '../m-language-selector/m-language-selector.module';

@NgModule({
    declarations: [NavbarItemComponent],
    imports: [CommonModule, TranslateModule, RouterModule, NgbDropdownModule, LanguageSelectorModule],
    exports: [NavbarItemComponent],
})
export class NavbarItemModule {}
