import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'a-paragraph',
    templateUrl: './a-paragraph.component.html',
    styleUrls: ['./a-paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
    @Input()
    content!: string;

    @Input()
    medium!: boolean;

    @Input()
    small!: boolean;

    @Input()
    brandColor!: boolean;

    @Input()
    grayColor!: boolean;

    @Input()
    errorColor!: boolean;

    @Input()
    bold!: boolean;

    @Input()
    noBottom!: boolean;

    @Input()
    textCenter!: boolean;

    @Input()
    contrast!: boolean;

    @Input() containerClass?: string;

    constructor() {}

    ngOnInit(): void {}
}
