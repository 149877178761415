import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageSectionItemComponent } from './m-image-section-item.component';
import { ParallaxModule } from 'ngx-parallax';
import { HeroSectionItemModule } from '../m-hero-section-item/m-hero.section-item.module';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { AvatarModule } from '../m-avatar/m-avatar.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    declarations: [ImageSectionItemComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        TranslateModule,
        ParallaxModule,
        HeroSectionItemModule,
        ImageModule,
        AvatarModule,
        LazyLoadImageModule,
    ],
    exports: [ImageSectionItemComponent],
})
export class ImageSectionItemModule {}
