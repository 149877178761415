import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { ApiService } from './api-service';

@Injectable({
    providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
    constructor(private transferState: TransferState, private apiService: ApiService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method === 'GET' && (req.responseType !== 'json' || req.url.includes('/assets/'))) {
            const url = this.apiService.getTransfterKey(req.method, req.url, req.params);
            const key = makeStateKey(url);
            const storedResponse: string = this.transferState.get(key, null) as any;
            if (storedResponse) {
                const response = new HttpResponse({ body: storedResponse, status: 200 });
                return of(response);
            }
        }

        return next.handle(req);
    }
}
