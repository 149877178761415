import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../../../utils/platform/platform.service';
import { CommonService } from '../../../utils/common/common.service';

@Component({
    selector: 'a-link',
    templateUrl: './a-link.component.html',
    styleUrls: ['./a-link.component.scss'],
})
export class LinkComponent implements OnInit {
    //<a-link [routerLink]="'/signup'" title="Go to W3Schools HTML section" >Alejandro</a-link>
    @Input()
    componentClass: string = '';
    @Input()
    url: string | null = '';
    @Input()
    isExternal: boolean = false;
    @Input()
    target: string = '_self';
    @Input()
    title: string = '';
    @Input()
    disabled: boolean = false;
    @Input()
    hasSlug: boolean = false;

    constructor(private router: Router, private platform: PlatformService, private commonService: CommonService) {}

    ngOnInit(): void {}

    click() {
        if (this.disabled || !this.url) return;

        if (this.url.startsWith('mailto:') && this.platform.isBrowser) {
            window.location.href = this.url;
            return;
        }

        if ((this.isExternal || (this.target && this.target !== '_self')) && this.platform.isBrowser)
            window.open(this.url, this.target);
        else {
            if (this.hasSlug) {
                this.commonService.redirectTo(this.url);
            } else {
                this.router.navigateByUrl(this.url);
            }
        }
    }
}
