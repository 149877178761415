<div
    class="m-avatar mt-2 mb-2"
    ngClass="componentClass"
    *ngIf="title">
    <div
        class="avatar-img"
        *ngIf="imgSrc"
        [ngStyle]="{ 'background-color': 'transparent' }"
        [lazyLoad]="imgSrc"
        [offset]="1000"></div>
    <div
        [ngClass]="{
            inline: !textHasBreak
        }">
        <a-paragraph
            [bold]="true"
            [contrast]="lightColor"
            [noBottom]="true"
            [content]="title | translate"></a-paragraph>
        <a-paragraph
            *ngIf="desc"
            [ngClass]="{
                'ml-2': !textHasBreak
            }"
            [contrast]="lightColor"
            [noBottom]="true"
            [content]="desc | translate"></a-paragraph>
    </div>
</div>
