import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'a-icon',
    templateUrl: './a-icon.component.html',
    styleUrls: ['./a-icon.component.scss'],
})
export class IconComponent implements OnInit {
    @Input() source!: string;
    @Input() iconSet!: boolean;
    @Input() containerClass?: string;
    @Input() white!: boolean;
    @Input() dark!: boolean;
    @Input() grey!: boolean;
    @Input() hasClass: boolean = true;

    constructor() {}

    ngOnInit(): void {}
}
