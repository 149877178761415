import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { MessageService } from '../message/message.service';
import { PlatformService } from '../platform/platform.service';

export type slideByScrollSide = 'left' | 'right';

@Injectable({
    providedIn: 'root',
})
export class AnimationsService {
    constructor(private message: MessageService, private platform: PlatformService) {}

    textScrambler(value: string, index: number, phrasesToDecode: string[]): any {
        if (this.platform.isBrowser) {
            const myObservable = interval(3000);
            return myObservable.subscribe(() => {
                const oldValue = phrasesToDecode[index];
                index++;
                if (index === phrasesToDecode.length) index = 0;
                value = phrasesToDecode[index];
                this.runDecode(oldValue, value);
            });
        }
        return null;
    }

    private runDecode(oldText: string, newText: string) {
        let chars = this.setText(oldText, newText);
        const maxEnd = Math.max(...chars.map((o) => o.end));
        const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));
        async function run(callback: any, message: MessageService) {
            for (let index = 0; index <= maxEnd; index++) {
                await timer(10);
                chars = callback(chars, index, message);
            }
        }

        run(this.updateText, this.message);
    }

    private setText(oldText: string, newText: string): any[] {
        const length = Math.max(oldText.length, newText.length);
        const chars = `!<>-_\\/[]{}—=+*^?#________`;
        const queue = [];
        for (var i = 0; i < length; i++) {
            const from = oldText[i] || '';
            const to = newText[i] || '';
            const start = Math.floor(Math.random() * 10);
            const end = start + Math.floor(Math.random() * 40);

            let num = Math.random() * chars.length;
            if (num > 18) {
                num = num - 18;
            }
            const char = chars[Math.floor(num)];
            queue.push({ from, to, start, end, char });
        }
        return queue;
    }

    private updateText(queue: any[], frame: number, message: MessageService): any[] {
        let output = '';
        let cons = '';
        for (var i = 0, n = queue.length; i < n; i++) {
            const _queue = queue[i];
            const from = _queue.from;
            const to = _queue.to;
            const start = _queue.start;
            const end = _queue.end;
            let char = _queue.char;

            if (frame >= end) {
                cons += to;
                output += to;
            } else if (frame < start) {
                output += from;
                cons += from;
            } else {
                output += '<span class="dec">' + char + '</span>';
                cons += char;
            }
        }
        message.sendMessage('textScrambler', output);
        return queue;
    }
}
