import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../logger/logger.service';
import { i18nLanguageDescModel } from './i18n.model';
import { AppSettings } from '../../../app.settings';
const log = new Logger('I18nService');
const languageKey = 'language';

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    defaultLanguage!: string;
    supportedLanguages!: string[];

    languagesSupported: i18nLanguageDescModel[] = [
        { language: 'en-US', text: 'English' },
        { language: 'es-ES', text: 'Español' },
    ];

    private langChangeSubscription!: Subscription;

    constructor(private translateService: TranslateService, private http: HttpClient) {
        // Embed languages to avoid extra HTTP requests

        /*         translateService.setTranslation('en-US', enUS);
        translateService.setTranslation('es-ES', esES); */
        AppSettings.SUPPORTED_LANGUAGES.forEach((lang) => {
            this.getTranslation(lang).subscribe((resUS) => {
                translateService.setTranslation(lang, resUS);
            });
        });
    }

    /**
     *
     * It unifies in a single .json file the different translation files.e.
     * @param lang language to use.
     */
    public getTranslation(lang: string): Observable<any> {
        const requestArray = [];
        requestArray.push(this.http.get(`${environment.baseUrl}/assets/translations/${lang}.json`));
        AppSettings.I18NPAGES.forEach((folder) => {
            requestArray.push(this.http.get(`${environment.baseUrl}/assets/translations/${folder}/${lang}.json`));
        });
        return forkJoin(requestArray).pipe(
            map((resp) => {
                return resp.reduce((a, b) => {
                    return Object.assign(a, b);
                });
            }),
        );
    }

    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param defaultLanguage The default language to use.
     * @param supportedLanguages The list of supported languages.
     */
    init(defaultLanguage: string, supportedLanguages: string[]) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';

        // Warning: this subscription will always be alive for the app's lifetime
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            localStorage.setItem(languageKey, event.lang);
        });
    }

    /**
     * Cleans up language change subscription.
     */
    destroy() {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    /**
     * Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @param language The IETF language code to set.
     */
    set language(language: string) {
        language =
            language || localStorage.getItem(languageKey) || (this.translateService.getBrowserCultureLang() ?? '');
        let isSupportedLanguage = this.supportedLanguages.includes(language);

        // If no exact match is found, search without the region
        if (language && !isSupportedLanguage) {
            language = language.split('-')[0];
            language =
                this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
            isSupportedLanguage = Boolean(language);
        }

        // Fallback if language is not supported
        if (!isSupportedLanguage) {
            language = this.defaultLanguage;
        }

        log.debug(`Language set to ${language}`);
        this.translateService.use(language);
    }

    /**
     * Gets the current language.
     * @return The current language code.
     */
    get language(): string {
        return this.translateService.currentLang;
    }
}
