import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageSectionComponent } from './o-image-section.component';
import { ImageModule } from '../../atoms/a-image/a-image.module';

@NgModule({
    declarations: [ImageSectionComponent],
    imports: [CommonModule, TranslateModule, ImageModule],
    exports: [ImageSectionComponent],
})
export class ImageSectionModule {}
