import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NoDataContentComponent } from './m-no-data-content.component';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';

@NgModule({
    declarations: [NoDataContentComponent],
    imports: [CommonModule, TranslateModule, IconModule, ParagraphModule],
    exports: [NoDataContentComponent],
})
export class NoDataContentModule {}
