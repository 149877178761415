import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    private _isBrowser: boolean;
    private _isServer: boolean;
    constructor(@Inject(PLATFORM_ID) platformId: Object) {
        this._isBrowser = isPlatformBrowser(platformId);
        this._isServer = isPlatformServer(platformId);
    }

    get isBrowser(): boolean {
        return this._isBrowser;
    }

    get isServer(): boolean {
        return this._isServer;
    }
}
