import { Component, OnInit, Input } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'a-loader',
    templateUrl: './a-loader.component.html',
    styleUrls: ['./a-loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    @Input() logoLoader!: any;
    @Input() background!: boolean;
    @Input() containerClass?: string;

    constructor() {}

    loader: AnimationOptions = {
        path: '/assets/animations/logo_animation.json',
    };

    @Input() isPrimaryColor: boolean = false;
    @Input() isPaginatorLoader: boolean = false;
    @Input() logo!: boolean;

    ngOnInit(): void {}

    animationCreated(animationItem: AnimationItem): void {}
}
