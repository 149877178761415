import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '../../../../@shared/base/component.base';

@Component({
    selector: 'm-parallax-card-item',
    templateUrl: './m-parallax-card-item.component.html',
    styleUrls: ['./m-parallax-card-item.component.scss'],
})
export class ParallaxCardItemComponent extends ComponentBase implements OnInit {
    @Input() parallaxContent!: string;
    @Input() title!: string;
    @Input() subTitle!: string;
    @Input() content!: string;
    @Input() containerClass?: string;
    @Input() parallaxOffset: number = -150;
    @Input() parallaxSpeed: number = 0.08;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
