import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpCacheService } from '../utils/cache/http-cache.service';
import { AppSettings } from '../../app.settings';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cache: HttpCacheService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRequestCachable(req)) {
            return next.handle(req);
        }
        const cachedResponse = this.cache.get(req);
        if (cachedResponse !== null) {
            return of(cachedResponse);
        }
        return next.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    this.cache.put(req, event);
                }
            }),
        );
    }
    private isRequestCachable(req: HttpRequest<any>) {
        if (AppSettings.MAX_CACHE_AGE === 0) return false;
        const _excluded = this.cache.excludedUrlToCache;
        if (_excluded && _excluded.includes(req.urlWithParams)) return false;
        return req.method === 'GET' && req.url.includes(environment.serverUrl);
    }
}
