import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './m-modal.component';
import { IconModule } from '../../atoms/a-icon/a-icon.module';

@NgModule({
    declarations: [ModalComponent],
    imports: [CommonModule, IconModule],
    exports: [ModalComponent],
})
export class ModalModule {}
