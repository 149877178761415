import { Routes, Route } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ShellComponent } from './shell.component';
import { Injectable } from '@angular/core';

/**
 * Provides helper methods to create routes.
 */
@Injectable({
    providedIn: 'root',
})
export class Shell {
    constructor(private http: HttpClient) {}
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: routes,
        };
    }

    getNavbarMenu(): any[] {
        return [
            {
                label: 'Our Work',
                url: '/work',
            },
            {
                label: 'Industries',
                url: '/industries',
            },
            {
                label: 'About Us',
                url: '/about',
            },
            {
                label: 'What we do',
                url: '/services',
            },
            {
                label: 'Initiatives',
                url: '/initiatives',
                subMenuItems: [
                    { label: 'investments', url: '/initiatives/investments' },
                    { label: 'open-source', url: '/initiatives/open-source' },
                    { label: 'human rights', url: '/initiatives/human-rights' },
                ],
            },
            {
                label: 'Company',
                subMenuItems: [
                    { label: 'Blog', url: '/blog' },
                    { label: 'Team', url: '/team' },
                    { label: 'Careers', url: '/careers' },
                    { label: 'Events', url: '/events' },
                    { label: 'Testimonials', url: '/testimonials' },
                ],
            },
            {
                label: 'Contact',
                url: '/contact',
                hideMobile: true,
                isBtn: true,
                hideAuth: true,
            },
        ];
    }
}
