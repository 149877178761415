import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SectionTitleComponent } from './m-section-title.component';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';

@NgModule({
    declarations: [SectionTitleComponent],
    imports: [CommonModule, TranslateModule, TitleModule, ParagraphModule],
    exports: [SectionTitleComponent],
})
export class SectionTitleModule {}
