import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardsComponent } from './o-cards.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { LoaderContainerModule } from '../../molecules/m-loader-container/m-loader-container.module';
import { CardsItemModule } from '../../molecules/m-cards-item/m-cards-item.module';
import { ButtonModule } from '../../atoms/a-button/a-button.module';
import { NoDataContentModule } from '../../molecules/m-no-data-content/m-no-data-content.module';
import { AvatarModule } from '../../molecules/m-avatar/m-avatar.module';

@NgModule({
    declarations: [CardsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgbCarouselModule,
        ImageModule,
        LoaderContainerModule,
        CardsItemModule,
        ButtonModule,
        NoDataContentModule,
        AvatarModule,
    ],
    exports: [CardsComponent],
})
export class CardsModule {}
