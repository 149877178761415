<div
    *ngIf="this.platform.isBrowser"
    class="o-bg-image-section"
    parallax
    [config]="{ initialValue: parallaxValue, ratio: parallaxSpeed }"
    [ngStyle]="{ 'background-color': 'transparent' }"
    [lazyLoad]="bgImage"
    [offset]="1000">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>
<div
    *ngIf="this.platform.isServer"
    class="o-bg-image-section"
    [ngStyle]="{ 'background-color': 'transparent' }"
    [lazyLoad]="bgImage"
    [offset]="1000"
    data-aos="fade-up"
    data-aos-delay="10"
    data-aos-once="true">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>
<ng-template #content>
    <div
        [ngClass]="{
            overlay: overlay
        }"></div>
    <div
        [ngClass]="[containerClass ? containerClass : '']"
        class="content">
        <ng-content select="[content]"></ng-content>
    </div>
</ng-template>
