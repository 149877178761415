import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '@app/@shared/utils/message/message.service';
import { Subscription } from 'rxjs';
import { MessageServiceModel } from '../../../utils/message/message.model';
import { ComponentBase } from '../../../base/component.base';

@Component({
    selector: 'm-loader-container',
    templateUrl: './m-loader-container.component.html',
    styleUrls: ['./m-loader-container.component.scss'],
})
export class LoaderContainerComponent extends ComponentBase implements OnInit {
    @Input() isPaginatorLoader: boolean = false;
    @Input() staticLogo!: boolean;
    @Input() containerClass?: string;

    public hasOnlyBackground!: boolean;
    public animatedLogo!: boolean;

    // The screen starts with the maximum opacity
    public opacityChange = 1;
    public splashTransition!: string;
    // First access the splash is visible
    public logoWidth: string = '300px';
    public logoHeight: string = '54px';
    readonly ANIMATION_DURATION = 1;
    subscriptionMessage: Subscription | undefined;
    @Input() loading: boolean = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        const subscription = (this.subscriptionMessage = this.messageService
            .getMessage()
            .subscribe((msj: MessageServiceModel) => {
                if (msj.type === 'loading') {
                    if (msj.payload.show) {
                        this.opacityChange = 1;
                        this.animatedLogo = msj.payload.animatedLogo;
                        this.hasOnlyBackground = msj.payload.hasOnlyBackground;
                        this.loading = true;
                    } else {
                        this.opacityChange = 0;
                        this.loading = false;
                    }
                    this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
                }
            }));
        this.subscriptions.push(subscription);
    }
}
