import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCarouselSectionComponent } from './o-image-carousel-section.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ng-gallery/lightbox';
import { GalleryModule } from 'ng-gallery';
import { ImageModule } from '../../atoms/a-image/a-image.module';

@NgModule({
    declarations: [ImageCarouselSectionComponent],
    imports: [CommonModule, TranslateModule, NgbCarouselModule, LightboxModule, GalleryModule, ImageModule],
    exports: [ImageCarouselSectionComponent],
})
export class ImageCarouselSectionModule {}
