<div
    class="a-icon"
    [ngClass]="[containerClass ? containerClass : '']">
    <svg-icon
        *ngIf="!iconSet"
        [ngClass]="hasClass ? (white && 'white') || (dark && 'dark') || (grey && 'grey') || 'primary' : ''"
        src="{{ source }}"></svg-icon>
    <i
        *ngIf="iconSet"
        [ngClass]="hasClass ? (white && 'white') || (dark && 'dark') || (grey && 'grey') || 'primary' : ''"
        class="{{ source }}"></i>
</div>
