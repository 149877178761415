import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LogoSliderComponent } from './o-logo-slider.component';
import { TabsModule } from '../../molecules/m-tabs/m-tabs.module';
import { HorizontalParallaxDirective } from '../../../utils/common/horizontal-parallax.directive';

@NgModule({
    declarations: [LogoSliderComponent],
    imports: [CommonModule, TranslateModule, TabsModule, HorizontalParallaxDirective],
    exports: [LogoSliderComponent],
})
export class LogoSliderModule {}
