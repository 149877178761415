import { Component, OnInit, Input, OnChanges, HostListener, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { CarouselModel } from '@app/@shared/utils/ng-bootstrap/m-carousel.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { CardModel } from '../../molecules/m-cards-item/m-cards-item.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'o-cards',
    templateUrl: './o-cards.component.html',
    styleUrls: ['./o-cards.component.scss'],
})
export class CardsComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() cards!: CardModel[];
    @Input() cardsPerRow: number = 2;
    @Input() idCarousel?: string;
    @Input() hasBg!: boolean;
    @Input() bgImage?: string;
    @Input() colClass: string = '';
    @Input() greyBackground: boolean = false;
    @Input() btnMoreLabel?: string;
    @Input() btnMoreCss: string = '';
    @Input() btnHref: string = '';
    @Input() paginatorLoading!: boolean;
    @Input() hasNoData!: boolean;
    @Input() containerClass?: string;
    carousel!: CarouselModel;
    isMobile: boolean = false;
    isBrowser: boolean;

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        this.ngAfterViewInit();
    }

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private config: NgbCarouselConfig,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        config.interval = 25000;
        config.wrap = true;
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.idCarousel && this.cards) {
            this.carousel = {
                bgImage: '',
                id: this.idCarousel,
                carouselContent: this.sliceIntoChunks(this.cards, this.isMobile ? 1 : this.cardsPerRow),
                overlay: false,
                hasCards: true,
            };
        }
    }

    ngAfterViewInit(): void {
        if (this.isBrowser && window) {
            const newMobile = window.innerWidth <= 767;
            if (this.isMobile != newMobile) {
                this.isMobile = newMobile;
                this.ngOnChanges();
            }
        }
    }

    private sliceIntoChunks(arr: any, chunkSize: any): any[] {
        const _chunkSize = parseInt(chunkSize);
        const res = [];
        for (let i = 0; i < arr.length; i += _chunkSize) {
            const chunk = arr.slice(i, i + _chunkSize);
            res.push(chunk);
        }
        return res;
    }

    get getBtnMoreLabel(): string {
        return this.btnMoreLabel ? this.translateService.instant(this.btnMoreLabel) : '';
    }

    click() {
        this.router.navigateByUrl(this.btnHref);
    }
}
