import { Component, OnInit, Input } from '@angular/core';
import { i18nLanguageDescModel } from '@app/@shared/utils/i18n/i18n.model';
import { I18nService } from '../../../utils/i18n/i18n.service';

@Component({
    selector: 'm-language-selector',
    templateUrl: './m-language-selector.component.html',
    styleUrls: ['./m-language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
    @Input() inNavbar = false;
    @Input() menuClass = '';
    @Input() label!: string;
    @Input() containerClass?: string;
    isOpen!: boolean;

    constructor(private i18nService: I18nService) {}

    ngOnInit() {}

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): i18nLanguageDescModel | undefined {
        return this.i18nService.languagesSupported.find((x) => this.i18nService.language === x.language);
    }

    get languages(): i18nLanguageDescModel[] {
        return this.i18nService.languagesSupported.filter((x) =>
            this.i18nService.supportedLanguages.includes(x.language),
        );
    }
}
