import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; */
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { FooterModule } from '../@shared/atomic/organisms/o-footer/o-footer.module';
import { NavbarModule } from '../@shared/atomic/organisms/o-navbar/o-navbar.module';

@NgModule({
    imports: [CommonModule, RouterModule, FooterModule, NavbarModule],
    declarations: [ShellComponent],
})
export class ShellModule {}
