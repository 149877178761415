import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './o-footer.component';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { FooterItemModule } from '../../molecules/m-footer-item/m-footer-item.module';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { LinkModule } from '../../atoms/a-link/a-link.module';
import { IconModule } from './../../atoms/a-icon/a-icon.module';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, TranslateModule, TitleModule, FooterItemModule, ParagraphModule, LinkModule, IconModule],
    exports: [FooterComponent],
})
export class FooterModule {}
