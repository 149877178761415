import { Component, OnInit, OnDestroy, AfterViewInit, Inject, PLATFORM_ID, isDevMode } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@shared';
import Aos from 'aos';
import { Logger } from './@shared/utils/logger/logger.service';
import { LoadingStateService } from './services/loading-state.service';
import { I18nService } from './@shared/utils/i18n/i18n.service';
import { isPlatformBrowser } from '@angular/common';
import { AppSettings } from './app.settings';
import { CommonService } from './@shared/utils/common/common.service';

const log = new Logger('App');

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    private isBrowser: boolean;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private loadingStateService: LoadingStateService,
        private i18nService: I18nService,
        private commonService: CommonService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        //this.httpCacheService.excludedUrlToCache = ['https://mcms-api.stage.scaliolabs.com/papi/events?page=1&limit=0&sort=-startDate&past=false'];

        log.debug('init');

        // Setup translations
        this.i18nService.init(AppSettings.DEFAULT_LANGUAGE, AppSettings.SUPPORTED_LANGUAGES);

        const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                switchMap((route) => route.data),
                untilDestroyed(this),
            )
            .subscribe((event) => {
                const title = event.title;
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });

        if (this.isBrowser) {
            Aos.init();
            if (isDevMode()) timer(0).subscribe(() => this.loadingStateService.initAppLoader());
        }
    }

    ngAfterViewInit() {
        if (this.isBrowser && !isDevMode()) this.loadingStateService.initAppLoader();
        this.commonService.dynamicImport();
    }

    ngOnDestroy() {
        this.i18nService.destroy();
    }
}
