import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'm-cta-banner',
    templateUrl: './m-cta-banner.component.html',
    styleUrls: ['./m-cta-banner.component.scss'],
})
export class CtaBannerComponent implements OnInit {
    @Input() secondaryBgColor: boolean = false;
    @Input() title?: string;
    @Input() desc?: string;
    @Input() showBtnAsLink: boolean = false;
    @Input() btnClassName?: string;
    @Input() btnLabel?: string;
    @Input() iconName?: string;
    @Input() imageName?: string;
    @Input() href?: string;
    @Input() btnLink?: string;
    @Input() iconSet: boolean = false;
    @Input() containerClass?: string;
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    constructor(private translateService: TranslateService, private router: Router) {}

    ngOnInit(): void {}

    get getBtnLabel(): string {
        return this.translateService.instant(this.btnLabel ?? '');
    }

    click() {
        if (this.href && this.href.length > 0) this.router.navigateByUrl(this.href);
        else this.onClick.emit(null);
    }
}
