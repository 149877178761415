import { LegalDoc } from '../@shared/cms/models/legaldoc.model';
import { TabsModel } from '../@shared/atomic/molecules/m-tabs/m-tabs.model';

export const mapLegalDocToTabsModel = (items: LegalDoc[], activeId: string): TabsModel[] => {
    return items.map((item, index) => {
        return {
            id: item._id,
            name: item.title,
            hasImages: false,
            hasContent: true,
            greyImages: false,
            active: item._id === activeId ? true : false,
            tabsContent: [{ content: item.body }],
            hasClickEvent: true,
        };
    });
};

export const mapLegalDocToFooterLinks = (items: LegalDoc[], baseUrl: string): any[] => {
    return items.map((item) => {
        return {
            label: item.title,
            href: `/${baseUrl}/${item.meta.slug}`,
        };
    });
};
