import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeroSectionItemComponent } from './m-hero-section-item.component';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { LinkModule } from '../../atoms/a-link/a-link.module';

@NgModule({
    declarations: [HeroSectionItemComponent],
    imports: [CommonModule, TranslateModule, ParagraphModule, ImageModule, TitleModule, LinkModule],
    exports: [HeroSectionItemComponent],
})
export class HeroSectionItemModule {}
