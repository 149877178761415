<div
    class="m-loader-container"
    [ngClass]="[containerClass ? containerClass : '']">
    <div
        *ngIf="loading"
        [ngClass]="{ loader: !isPaginatorLoader, 'page-loader': isPaginatorLoader }">
        <a-loader
            *ngIf="animatedLogo"
            [logo]="true"></a-loader>
        <a-loader
            *ngIf="hasOnlyBackground"
            [background]="true"></a-loader>
    </div>
    <div class="fadeIn">
        <ng-content *ngIf="!loading"></ng-content>
    </div>
</div>
