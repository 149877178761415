import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './o-navbar.component';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { ThemeToggleModule } from '../../atoms/a-theme-toggle/a-theme-toggle.module';
import { NavbarItemModule } from '../../molecules/m-navbar-item/m-navbar-item.module';

@NgModule({
    declarations: [NavbarComponent],
    imports: [
        CommonModule,
        TranslateModule,
        AngularSvgIconModule,
        IconModule,
        ThemeToggleModule,
        NavbarItemModule,
        RouterModule,
    ],
    exports: [NavbarComponent],
})
export class NavbarModule {}
