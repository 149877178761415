<ng-template
    #content
    let-modal>
    <div
        class="m-modal"
        [class.modal-form]="hasForm"
        [class.video]="hasVideo"
        [ngClass]="[containerClass ? containerClass : '']"
        tabindex="-1">
        <div class="modal-dialog position-relative">
            <div [ngClass]="{ 'modal-content': true, 'modal-content-form': hasForm }">
                <ng-content></ng-content>
                <div
                    class="close-background"
                    (click)="modal.dismiss('close')">
                    <div data-dismiss="modal">
                        <a-icon
                            [white]="true"
                            data-dismiss="modal"
                            aria-label="Close"
                            [iconSet]="true"
                            source="lni lni-close"></a-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
