import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ArgumentsService } from '../../../utils/arguments/arguments.service';

@Component({
    selector: 'm-cards-item',
    templateUrl: './m-cards-item.component.html',
    styleUrls: ['./m-cards-item.component.scss'],
})
export class CardsItemComponent implements OnInit {
    @Input() title?: string;
    @Input() desc?: string;
    @Input() headerTitle?: string;
    @Input() imgSrc?: string;
    @Input() mediumSizeImg: boolean = false;
    @Input() backgroundImgSrc?: string;
    @Input() iconName?: string;
    @Input() iconSet: boolean = true;
    @Input() iconLeft: boolean = false;
    @Input() halfTitle?: string;
    @Input() greyScaleImage: boolean = false;
    @Input() btnTitle?: string;
    @Input() isCard: boolean = false;
    @Input() isCredential: boolean = false;
    @Input() noSideBorders: boolean = false;
    @Input() borderBottom: boolean = false;
    @Input() hasLink: boolean = false;
    @Input() linkLabel?: string;
    @Input() linkUrl?: string;
    @Input() hasLines: boolean = false;
    @Input() padding0: boolean = false;
    @Input() w50: boolean = false;
    @Input() w100: boolean = false;
    @Input() regularSize: boolean = false;
    @Input() noPadding: boolean = false;
    @Input() hasModal: boolean = false;
    @Input() modalId?: string;
    @Input() isClickable: boolean = false;
    @Input() darkMode: boolean = false;
    @Input() callback?: () => void;
    @Input() containerClass?: string;

    constructor(private router: Router, private argService: ArgumentsService) {}

    click() {
        if (this.linkUrl) this.btnClick(this.linkUrl);
        else if (this.callback) this.callback();
    }

    ngOnInit(): void {}

    btnClick(link: string) {
        this.router.navigateByUrl(link);
    }
}
