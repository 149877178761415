import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ButtonType = 'button' | 'reset' | 'submit';

@Component({
    selector: 'a-button',
    templateUrl: './a-button.component.html',
    styleUrls: ['./a-button.component.scss'],
})
export class ButtonComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    @Input()
    type!: ButtonType;

    @Input()
    buttonStyle!: string;

    @Input()
    size!: string;

    @Input()
    disabled!: boolean;

    @Input()
    loading: boolean = false;

    @Input() containerClass?: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    handleClick() {
        this.onClick.emit(null);
    }

    get buttonStyleCss(): string {
        if (!this.buttonStyle) return '';
        return this.buttonStyle;
    }

    get sizeCss(): string {
        if (!this.size) return '';
        return this.size;
    }
}
