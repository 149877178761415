import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    TemplateRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalParam } from './m-modal.model';

@Component({
    selector: 'm-modal',
    templateUrl: './m-modal.component.html',
    styleUrls: ['./m-modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
    @Input() hasVideo!: boolean;
    @Input() hasForm!: boolean;
    @Input() isOpen: ModalParam = { closeResult: '', open: false };
    @Input() containerClass?: string;
    @Output() isOpenChange: EventEmitter<ModalParam> = new EventEmitter();
    @Output() onClose: EventEmitter<null> = new EventEmitter();
    @ViewChild('content') modalContent!: TemplateRef<any>;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isOpen) {
            if (changes.isOpen.currentValue.open) this.open();
            else this.close();
        }
    }

    close() {
        this.modalService.dismissAll('close');
        this.onClose.emit();
    }

    open() {
        this.modalService.open(this.modalContent, { size: 'lg', centered: true }).result.then(
            (result) => {
                this.isOpenChange.emit({ closeResult: `Closed with: ${result}`, open: false });
            },
            (reason) => {
                this.isOpenChange.emit({ closeResult: `Dismissed ${this.getDismissReason(reason)}`, open: false });
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
