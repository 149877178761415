<div
    class="m-tabs"
    [ngClass]="[containerClass ? containerClass : '']"
    *ngIf="!hasNoData">
    <nav>
        <div
            class="nav nav-tabs"
            id="nav-tab"
            role="tablist">
            <ng-container *ngFor="let item of tabsConfig; let i = index">
                <span
                    [ngClass]="{ 'nav-link': true, active: item.active }"
                    [id]="'#' + item.id"
                    data-toggle="tab"
                    role="tab"
                    (click)="click(item)"
                    *ngIf="tabsConfig.length >= 2">
                    {{ item.name | translate }}
                </span>
            </ng-container>
        </div>
    </nav>
    <div
        class="tab-content"
        id="nav-tabContent">
        <ng-container *ngFor="let item of contentList; let i = index">
            <div
                [ngClass]="{ 'tab-pane fade show': true, active: item.active }"
                [id]="item.id"
                role="tabpanel">
                <div
                    *ngIf="!item.hasLogos"
                    [ngClass]="{ 'logo-image-container': item.hasImages }">
                    <ng-container *ngFor="let itemTab of item.tabsContent; let i = index">
                        <div>
                            <a-image
                                [greyScale]="item.greyImages"
                                *ngIf="item.hasImages"
                                [imageConfig]="itemTab.imgSrc"
                                [alt]="itemTab.content | translate"></a-image>
                            <div class="policies-container">
                                <p
                                    *ngIf="item.hasContent"
                                    [innerHtml]="itemTab.content"></p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div
                    class="logo-container"
                    *ngIf="item.hasLogos">
                    <ng-container *ngFor="let grp of logoGroup">
                        <div class="logo-row d-flex align-items-center">
                            <ng-container *ngFor="let logo of grp">
                                <a-image
                                    [greyScale]="item.greyImages"
                                    [imageConfig]="logo.imgSrc"
                                    [alt]="item.name | translate"></a-image>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<m-no-data-content
    content="common.no.content.data.label"
    *ngIf="hasNoData"></m-no-data-content>
