import { Component, OnInit, Input } from '@angular/core';
import { SectionTitleType, SectionTitleAlign } from './m-section-title.model';
import { GenerationComponent } from '../../../generator/generation/generation.model';

@Component({
    selector: 'm-section-title',
    templateUrl: './m-section-title.component.html',
    styleUrls: ['./m-section-title.component.scss'],
})
export class SectionTitleComponent extends GenerationComponent implements OnInit {
    @Input() halfTitle?: string;
    @Input() title?: string;
    @Input() desc?: string;
    @Input() type!: SectionTitleType;
    @Input() align!: SectionTitleAlign;
    @Input() componentClass?: string;
    @Input() w100: boolean = false;
    @Input() lightColor: boolean = false;
    @Input() titleNoWrap: boolean = true;

    constructor() {
        super();
    }

    ngOnInit(): void {}
}
