import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Cache } from './cache';
import { CacheEntry } from './cache-entry';
import { AppSettings } from '../../../app.settings';

@Injectable({
    providedIn: 'root',
})
export class HttpCacheService implements Cache {
    excludedUrlToCache: string[] = [];
    cacheMap = new Map<string, CacheEntry>();
    get(req: HttpRequest<any>): HttpResponse<any> | null {
        const entry = this.cacheMap.get(req.urlWithParams);
        if (!entry) {
            return null;
        }
        const isExpired = Date.now() - entry.entryTime > AppSettings.MAX_CACHE_AGE;
        if (!isExpired) this.put(req, entry.response);
        return isExpired ? null : entry.response;
    }
    put(req: HttpRequest<any>, res: HttpResponse<any>): void {
        const entry: CacheEntry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
        this.cacheMap.set(req.urlWithParams, entry);
        this.deleteExpiredCache();
    }
    private deleteExpiredCache() {
        this.cacheMap.forEach((entry) => {
            if (Date.now() - entry.entryTime > AppSettings.MAX_CACHE_AGE) {
                this.cacheMap.delete(entry.url);
            }
        });
    }
}
