import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
    Shell.childRoutes([
        //Shell.childRoutes([{ path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) }]),
        // Fallback when no prior route is matched
        /*     { path: '**', redirectTo: '', pathMatch: 'full' }, */
        { path: '**', redirectTo: '', pathMatch: 'full' },
    ]),
];

/* {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
} */

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
