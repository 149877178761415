import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BoxImageItemComponent } from './m-box-image-item.component';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { ModalModule } from '../m-modal/m-modal.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    declarations: [BoxImageItemComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ParagraphModule,
        TitleModule,
        ImageModule,
        ModalModule,
        LazyLoadImageModule,
    ],
    exports: [BoxImageItemComponent],
})
export class BoxImageItemModule {}
