import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardsItemComponent } from './m-cards-item.component';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { LinkModule } from '../../atoms/a-link/a-link.module';
import { ModalModule } from '../m-modal/m-modal.module';

@NgModule({
    declarations: [CardsItemComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ImageModule,
        ParagraphModule,
        TitleModule,
        IconModule,
        LinkModule,
        ModalModule,
    ],
    exports: [CardsItemComponent],
})
export class CardsItemModule {}
