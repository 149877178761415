import { Injectable } from '@angular/core';

export interface Credentials {
    // Customize received credentials here
    authHeader: string;
    id_token: string;
    session: any;
    user: any;
}

const credentialsKey = 'mcms.session';

@Injectable({
    providedIn: 'root',
})
export class CredentialsService {
    private _credentials: Credentials | null = null;

    constructor() {
        const savedCredentials = localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    /**
     * Checks is the user is authenticated.
     * @return True if the user is authenticated.
     */
    isAuthenticated(): boolean {
        return !!this.credentials;
    }

    /**
     * Gets the user credentials.
     * @return The user credentials or null if the user is not authenticated.
     */
    get credentials(): Credentials | null {
        return this._credentials;
    }

    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param credentials The user credentials.
     * @param remember True to remember credentials across sessions.
     */
    setCredentials(credentials?: Credentials, remember?: boolean) {
        this._credentials = credentials || null;

        if (credentials) {
            localStorage.setItem(credentialsKey, JSON.stringify(credentials));
        } else {
            localStorage.removeItem(credentialsKey);
        }
    }
}
