import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'm-no-data-content',
    templateUrl: './m-no-data-content.component.html',
    styleUrls: ['./m-no-data-content.component.scss'],
})
export class NoDataContentComponent implements OnInit {
    @Input() content!: string;
    @Input() containerClass?: string;

    constructor() {}

    ngOnInit(): void {}
}
