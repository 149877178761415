import { Directive, Input, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { fromEvent, Subscription, timer } from 'rxjs';
import { PlatformService } from '../platform/platform.service';

export type HorizontalParallaxSide = 'left' | 'right';

export interface HorizontalParallaxModel {
    side: HorizontalParallaxSide;
    offset: number;
    speed: number; //Must be between 1 and 0
}

export type EventAnimateType = string | EventAnimate;

export interface EventAnimate {
    applyOn: string;
    animation: string | ((elem: any, event: any) => void);
}

@Directive({
    standalone: true,
    selector: '[horizontalParallax]',
})
export class HorizontalParallaxDirective implements OnDestroy, AfterViewInit {
    @Input('horizontalParallax') obj!: HorizontalParallaxModel;
    subscriptions: Subscription[] = [];
    constructor(private element: ElementRef, private platform: PlatformService) {}

    ngAfterViewInit() {
        if (this.platform.isBrowser) {
            timer(2000).subscribe(() => {
                const subscription = fromEvent(window, 'scroll').subscribe((ev: any) => {
                    const el = this.element.nativeElement;
                    if (el) {
                        const elementPosition: number = el.getBoundingClientRect().top;
                        let translate = (elementPosition + this.obj.offset) * this.obj.speed;
                        translate = (window.outerWidth * translate) / 1440;

                        if (this.obj.side === 'right') translate = translate * -1;
                        el.style.transform = 'translateX(' + translate + 'px)';
                    }
                });
                this.subscriptions.push(subscription);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
            this.subscriptions = [];
        }
    }
}
