import { Injectable } from '@angular/core';
import { MediaPlayerModel } from './media-player.model';
import { ResourceMedia } from '../../cms/models/resource.model';
import { MessageService, messageAction } from '../message/message.service';

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    static media: MediaPlayerModel;
    constructor() {}

    get media() {
        return MediaService.media;
    }

    stopReproduce() {
        MediaService.media = {
            isExternal: true,
            ctrlType: 'default',
            provider: 'video',
            mimeType: 'video/mp4',
        } as MediaPlayerModel;
    }

    static getContentFromUrl(url: string): string {
        const extension = url.split('.').pop();
        switch (extension?.toLowerCase()) {
            case 'flv':
                return 'video/x-flv';
            case 'mp4':
                return 'video/mp4';
            case 'm3u8':
                return 'application/x-mpegURL';
            case 'ts':
                return 'video/MP2T';
            case '3gp':
                return 'video/3gpp';
            case 'mov':
                return 'video/quicktime';
            case 'avi':
                return 'video/x-msvideo';
            case 'wmv':
                return 'video/x-ms-wmv';
            default:
                return '';
        }
    }

    reproduceMedia(resourceMedia: ResourceMedia[], msjService: MessageService, typeMsj: messageAction = 'modal'): void {
        if (!resourceMedia || resourceMedia.length === 0) return;

        switch (resourceMedia[0].name) {
            case 'video':
                MediaService.media = {
                    isExternal: true,
                    src: resourceMedia[0].url,
                    ctrlType: 'none',
                    provider: 'video',
                    mimeType: MediaService.getContentFromUrl(resourceMedia[0].url),
                } as MediaPlayerModel;
                break;
            case 'youtube':
                MediaService.media = {
                    id: resourceMedia[0].url.split('/').pop(),
                    ctrlType: 'none',
                    provider: 'youtube',
                } as MediaPlayerModel;
                break;
            case 'vimeo':
                MediaService.media = {
                    id: resourceMedia[0].url.split('/').pop(),
                    ctrlType: 'none',
                    provider: 'vimeo',
                } as MediaPlayerModel;
                break;
            case 'dailymotion':
                MediaService.media = {
                    id: resourceMedia[0].url.split('/').pop(),
                    ctrlType: 'none',
                    provider: 'dailymotion',
                } as MediaPlayerModel;
                break;
            case 'dash':
                MediaService.media = {
                    src: resourceMedia[0].url,
                    ctrlType: 'none',
                    provider: 'dash',
                } as MediaPlayerModel;
                break;
            case 'hls':
                MediaService.media = {
                    src: resourceMedia[0].url,
                    ctrlType: 'none',
                    provider: 'hls',
                    mimeType: 'application/x-mpegURL',
                } as MediaPlayerModel;
                break;
            default:
                //audio
                MediaService.media = {
                    src: resourceMedia[0].url,
                    ctrlType: 'none',
                    provider: 'audio',
                    mimeType: 'audio/mp3',
                } as MediaPlayerModel;
                break;
        }

        msjService.sendMessage(typeMsj, true);
    }
}
