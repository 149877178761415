import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../platform/platform.service';
import { DOCUMENT } from '@angular/common';
import { AppSettings } from '@src/app/app.settings';
import { ICdnElements } from '../../../app.settings';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _renderer2: Renderer2;
    constructor(
        private router: Router,
        private platform: PlatformService,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document,
    ) {
        this._renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    dynamicImport() {
        if (this.platform.isServer) return;

        AppSettings.CDN_ELEMENTS.forEach((element: ICdnElements) => {
            if (!element.active) return;

            if (element.elType === 'script') {
                let script = this._renderer2.createElement('script');
                if (element.type) script.type = element.type;
                if (element.isAsync) script.setAttribute('async', '');

                if (element.isDefer) script.setAttribute('defer', '');

                script.src = element.src;

                this._renderer2.appendChild(this._document.body, script);
            } else {
                let script = this._renderer2.createElement('link');
                if (element.rel) script.rel = element.rel;
                if (element.isCrossorigin) script.setAttribute('crossorigin', '');

                script.href = element.src;

                this._renderer2.appendChild(this._document.head, script);
            }
        });
    }

    scrollToTargetAdjusted(el: string, headerOffset: number = 0, elContainer: string) {
        const obj = document.getElementById(el);
        if (obj) {
            const container = document.getElementById(elContainer);
            if (container) {
                const cPos = container.getBoundingClientRect();
                const elPos = obj.getBoundingClientRect();
                const top = elPos.top - cPos.top + container.scrollTop - headerOffset;

                container.scrollTop = top;
            }
        }
    }

    scrollObjFromBody(el: string) {
        const obj = document.getElementById(el);
        if (obj) {
            obj.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }

    getBestImgFormat(urlImg: string) {
        if (urlImg && urlImg.includes('s3.amazonaws.com'))
            return urlImg.replace(/\.(png|jpg|jpeg)/i, '.webp');
        return urlImg;
    }

    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
    }

    setNgCarouselAriaAttrs(){
        if (this.platform.isBrowser){
            const el = document.querySelectorAll('.carousel-indicators');
            if (el.length > 0){
                Array.from(el.item(0).children).forEach(btn => {
                    const text = btn.getAttribute("aria-labelledby");
                    if (text)
                        btn.setAttribute("aria-label", text);
                });
               return true;
            }
        }
        return false;
    }

    setDetailPageImgAlt(alt: string){
        if (this.platform.isBrowser){
            const el = document.querySelectorAll('img');
            if (el.length > 0){
                Array.from(el).forEach(img => {
                    const _alt = img.getAttribute("alt");
                    if (!_alt){
                        img.setAttribute("alt", alt);
                    }
                        
                });
               return true;
            }
        }
        return false;
    }

    setDynamicCols(index: number, total: number, cols: number, defaultClass: string) {
        const itemsXRow = total / cols;
        const truncItems = Math.trunc(itemsXRow) * cols;
        if (truncItems >= index + 1) {
            return defaultClass;
        } else {
            const lastItems = total - truncItems;
            switch (lastItems) {
                case 4:
                    return 'col-md-3';
                case 3:
                    return 'col-md-4';
                case 2:
                    return 'col-md-6';
                default:
                    return 'col-md-12 col-centered';
            }
        }
    }


}
