<ng-container>
    <div
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
        class="m-parallax-card-item">
        <div class="content position-relative">
            <p
                *ngIf="this.platform.isBrowser"
                [horizontalParallax]="{ side: 'right', offset: parallaxOffset, speed: parallaxSpeed }"
                class="h2 fw-bold parallax-content">
                {{ parallaxContent }}
            </p>
            <p
                *ngIf="this.platform.isServer"
                class="h2 fw-bold parallax-content">
                {{ parallaxContent }}
            </p>
            <p class="h3 mb-2 brand-color">{{ title }}</p>
            <h5 class="fw-bold">{{ subTitle }}</h5>
            <p>{{ content }}</p>
        </div>
    </div>
</ng-container>
