import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParallaxCardItemComponent } from './m-parallax-card-item.component';
import { HorizontalParallaxDirective } from '../../../../@shared/utils/common/horizontal-parallax.directive';

@NgModule({
    declarations: [ParallaxCardItemComponent],
    imports: [CommonModule, TranslateModule, HorizontalParallaxDirective],
    exports: [ParallaxCardItemComponent],
})
export class ParallaxCardItemModule {}
