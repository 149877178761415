import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ComponentBase } from '../../../../@shared/base/component.base';

@Component({
    selector: 'o-bg-image-section',
    templateUrl: './o-bg-image-section.component.html',
    styleUrls: ['./o-bg-image-section.component.scss'],
    providers: [NgbCarouselConfig],
})
export class BgImageSectionComponent extends ComponentBase implements OnInit, OnChanges {
    @Input() overlay: boolean = false;
    @Input() bgImage!: string;
    @Input() alt?: string;
    @Input() containerClass?: string;
    @Input() height?: string;
    @Input() parallaxValue: number = -100;
    @Input() parallaxSpeed: number = 0.2;

    constructor(config: NgbCarouselConfig) {
        super();
        config.interval = 5000;
        config.wrap = true;
    }

    ngOnInit(): void {}
    ngOnChanges(changes: SimpleChanges): void {}

    getFooterClassName(arg: string): String {
        if (!arg) return '';
        return arg;
    }
}
