import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '@src/app/app.settings';

interface HttpHeaderOptions {
    headers: HttpHeaders;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    getStorage(): any | null {
        let result = JSON.parse(
            localStorage.getItem('mcms.session') || `{ "id_token": "", "session": { "site": "${AppSettings.SITE}"}}`,
        );
        if (!result.session.site) result.session.site = AppSettings.SITE;
        return result;
    }

    getUserEmail(): string | null {
        const { user }: any = this.getStorage();
        if (user) return user.email;
        return null;
    }

    getToken(): string | null {
        const { id_token }: any = this.getStorage();
        return id_token;
    }

    getTransfterKey(method: string, url: string, params: HttpParams): string {
        // make the params encoded same as a url so it's easy to identify
        const encodedParams = params
            .keys()
            .sort()
            .map((k) => `${k}=${params.getAll(k)}`)
            .join('&');
        return (method === 'GET' ? 'G.' : 'H.') + url + '?' + encodedParams;
    }

    get(url: string, httpOption?: HttpHeaderOptions): Observable<any> {
        return this.http.get(url, httpOption);
    }

    post(url: string, data: any, httpOption?: HttpHeaderOptions): Observable<any> {
        return this.http.post(url, data, httpOption);
    }

    put(url: string, data: any, httpOption?: HttpHeaderOptions): Observable<any> {
        return this.http.put(url, data, httpOption);
    }

    patch(url: string, data: any, httpOption?: HttpHeaderOptions): Observable<any> {
        return this.http.patch(url, data, httpOption);
    }

    delete(url: string, httpOption?: HttpHeaderOptions): Observable<any> {
        return this.http.delete(url, httpOption);
    }
}
