<div
    class="o-logo-slider"
    [ngClass]="[containerClass ? containerClass : '']">
    <ng-content select="[header]"></ng-content>
    <ng-container *ngIf="tabs">
        <m-tabs [tabsConfig]="tabs"></m-tabs>
    </ng-container>
</div>
<ng-container *ngIf="!tabs">
    <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
        class="logo-container container-fluid pt-4 pb-7">
        <div
            *ngIf="hasLogos"
            class="logo-row d-flex align-items-center justify-content-between mb-5"
            [horizontalParallax]="{ side: 'left', offset: -350, speed: 0.3 }">
            <ng-content select="[logos]"></ng-content>
        </div>
        <div
            *ngIf="hasLogos2"
            class="logo-row d-flex align-items-center justify-content-between mb-5"
            [horizontalParallax]="{ side: 'right', offset: -350, speed: 0.6 }">
            <ng-content select="[logos2]"></ng-content>
        </div>
        <div
            *ngIf="hasLogos3"
            class="logo-row d-flex align-items-center justify-content-between mb-5"
            [horizontalParallax]="{ side: 'left', offset: -350, speed: 0.9 }">
            <ng-content select="[logos3]"></ng-content>
        </div>
    </div>
</ng-container>
