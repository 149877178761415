<button
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[buttonStyle, sizeCss, containerClass ? containerClass : '']"
    (click)="handleClick()">
    <span *ngIf="!loading">
        <ng-content></ng-content>
    </span>
    <a-loader
        *ngIf="loading"
        [isPaginatorLoader]="true"></a-loader>
</button>
