import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TabsModel } from './m-tabs.model';

@Component({
    selector: 'm-tabs',
    templateUrl: './m-tabs.component.html',
    styleUrls: ['./m-tabs.component.scss'],
})
export class TabsComponent implements OnInit {
    @Input() tabsConfig!: Array<TabsModel>;
    @Input() hasNoData!: boolean;
    @Input() containerClass?: string;

    @Output()
    tabChange = new EventEmitter<TabsModel>();

    logoGroup!: Array<any>;
    constructor() {}

    ngOnInit(): void {
        this.logoGroup = (this.tabsConfig && this.tabsConfig.length > 0 && this.tabsConfig[0].tabsContent) || [];
    }

    get contentList(): Array<TabsModel> {
        if (!this.tabsConfig) return [];
        const result = this.tabsConfig.filter((x) => x.hasContent);
        return result;
    }

    click(tab: TabsModel) {
        this.logoGroup = tab.tabsContent;
        this.tabChange.emit(tab);
    }
}
