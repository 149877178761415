<ng-container *ngIf="title1">
    <h1
        class="bold-weight"
        [class.contrast-color]="contrast"
        [class.regular-weight]="regular"
        [class.mb-0]="noBottom"
        [class.mb-5]="mb5"
        [class.text-center]="textCenter"
        [class.ellipsis]="truncate"
        [class.gradient-text]="gradient"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="title1"></h1>
</ng-container>
<ng-container *ngIf="title2">
    <h2
        class="bold-weight"
        [class.contrast-color]="contrast"
        [class.regular-weight]="regular"
        [class.mb-0]="noBottom"
        [class.mb-5]="mb5"
        [class.text-center]="textCenter"
        [class.ellipsis]="truncate"
        [class.gradient-text]="gradient"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="title2"></h2>
</ng-container>

<ng-container *ngIf="title3">
    <h3
        class="bold-weight"
        [class.contrast-color]="contrast"
        [class.regular-weight]="regular"
        [class.mb-0]="noBottom"
        [class.mb-5]="mb5"
        [class.text-center]="textCenter"
        [class.ellipsis]="truncate"
        [class.gradient-text]="gradient"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="title3"></h3>
</ng-container>

<ng-container *ngIf="title4">
    <h4
        class="bold-weight"
        [class.contrast-color]="contrast"
        [class.regular-weight]="regular"
        [class.mb-0]="noBottom"
        [class.mb-5]="mb5"
        [class.text-center]="textCenter"
        [class.ellipsis]="truncate"
        [class.gradient-text]="gradient"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="title4"></h4>
</ng-container>

<ng-container *ngIf="title5">
    <h5
        class="bold-weight"
        [class.contrast-color]="contrast"
        [class.regular-weight]="regular"
        [class.mb-0]="noBottom"
        [class.mb-5]="mb5"
        [class.text-center]="textCenter"
        [class.ellipsis]="truncate"
        [class.gradient-text]="gradient"
        [ngClass]="[containerClass ? containerClass : '']"
        [innerHtml]="title5"></h5>
</ng-container>
