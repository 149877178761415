import { CardModel } from './../../@shared/atomic/molecules/m-cards-item/m-cards-item.model';
import { Component, OnInit } from '@angular/core';
import { TeamMember } from '@app/@shared/cms/models/team-member.model';
import { PageBase } from '../../@shared/base/page.base';
import { MediaService } from '../../@shared/utils/media/media.service';
import { MediaPlayerModel } from '../../@shared/utils/media/media-player.model';
import { VideoOptions } from '../../@shared/atomic/organisms/o-video-section/o-video.model';
import { MessageServiceModel } from '../../@shared/utils/message/message.model';
import { AnimationsService } from '@src/app/@shared/utils/common/animations.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PageBase implements OnInit {
    quote: string | undefined;
    isLoading = false;
    isPlaying = true;
    headerVideoBlock!: VideoOptions;
    joinSection!: any[];
    media!: MediaPlayerModel;
    lidershipMembers!: TeamMember[];
    cardsGlobalTech!: any[];
    innovativeProducts!: any[];
    cardsWithLinkCarousel: CardModel[] = [];
    content!: string;
    contentDefault: string = `We're a digital product agency <span class='brand-color'>advancing technology</span> for <br>`;

    phrasesToDecode = [
        "the world's leading companies.",
        'machine learning & augmented reality.',
        'surgical robots & AI-based systems.',
        'marketing & advertising organizations.',
        'healthcare & biotech.',
        'e-commerce & bidding platforms.',
        'smart devices & connected vehicles.',
        'think tanks, nonprofits, and NGOs.',
        'the media & entertainment industry.',
        'researchers and scientists.',
        'real-time collaboration platforms.',
    ];

    constructor(public mediaService: MediaService, public animationsService: AnimationsService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.content = this.contentDefault + this.phrasesToDecode[0];

        const messageSubs = this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
            if (msj.type === 'textScrambler') {
                this.content = this.contentDefault + msj.payload;
            }
        });
        this.subscriptions.push(messageSubs);

        const scramblerSub = this.animationsService.textScrambler(this.content, 0, this.phrasesToDecode);
        if (scramblerSub) this.subscriptions.push(scramblerSub);

        this.headerVideoBlock = {
            fluid: true,
            aspectRatio: '16:9',
            autoplay: true,
            loop: true,
            controls: false,
            muted: true,
            preload: 'metadata',
            poster: 'assets/videos/poster-video.jpg',
            sources: [
                {
                    src: 'https://scalio-assets.scaliolabs.com/assets/video/scalio-home-hero3-d5d2718c42.mp4',
                    type: 'video/mp4',
                },
            ],
        } as VideoOptions;
        this.joinSection = [
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
        ];
        (this.cardsGlobalTech = [
            {
                parallaxContent: '600+',
                title: '600+ PROJECTS',
                subTitle: 'In 30+ Industries',
                content:
                    "Over 10 years, we've completed 600+ projects across 30+ industries. 9 of our clients have been acquired. Intuitive solutions made for the most complex challenges.",
            },
            {
                parallaxContent: '100+',
                title: '100+ MILLON',
                subTitle: 'Delighted Users',
                content:
                    'Our experts have crafted products that have engaged over 100 million users across dozens of verticals. From finanical tech, to healthcare, to gaming - we know how to deliver world-class experiences.',
            },
            {
                parallaxContent: '$1 Bil',
                title: '$1 BILLION+',
                subTitle: 'In Revenue',
                content:
                    'With hundreds of products shipped, our experts have delivered over $1 billion in revenue on products over the past 10 years.',
            },
            {
                parallaxContent: '75+',
                title: '75+ SPECIALISTS',
                subTitle: 'Globally',
                content:
                    'A global workforce celebrated in diversity of skill & background. 75 unique technical specialties, 43 countries, and one very special mission.',
            },
        ]),
            (this.innovativeProducts = [
                {
                    icon: 'lni lni-code-alt',
                    title: 'Mobile Engineering',
                },
                {
                    icon: 'lni lni-pencil-alt',
                    title: 'Design & User Experience',
                },
                {
                    icon: 'lni lni-rocket',
                    title: 'Strategy & Growth',
                },
                {
                    icon: 'lni lni-code',
                    title: 'Web Engineering',
                },
                {
                    icon: 'lni lni-cloud',
                    title: 'DevOps & Hosting',
                },
                {
                    icon: 'lni lni-cog',
                    title: 'Support & Maintenance',
                },
            ]),
            (this.cardsWithLinkCarousel = [
                {
                    desc: '<blockquote><p>Scalio never ceases to impress - from our mobile app to various web platforms...</p></blockquote>',
                    iconSet: false,
                    iconName: '/assets/icons/company-uber.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/frederique-dame',
                },
                {
                    desc: '<blockquote><p>When it comes to building software, I cannot recommend a better team than Scalio.</p></blockquote>',
                    iconSet: false,
                    iconName: '/assets/icons/company-ibm.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/mike-markson',
                },
                {
                    desc: "<blockquote><p>This team knows what they're doing.</p></blockquote>",
                    iconSet: false,
                    iconName: '/assets/icons/company-softbank.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/ted-fike',
                },
                {
                    desc: "<blockquote><p>I have nothing but great things to say about working with Scalio. They are one of the good ones...</p></blockquote>",
                    iconSet: false,
                    iconName: '/assets/icons/company-shell.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/christopher-boutiette',
                },
                {
                    desc: "<blockquote><p>Solid design, effective communication, flexibility when issues arise or requirements evolve.</p></blockquote>",
                    iconSet: false,
                    iconName: '/assets/icons/company-lyft.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/bakari-brock',
                },
                {
                    desc: "<blockquote><p>Scalio took the time to understand our domain and create a revolutionary product for our group...</p></blockquote>",
                    iconSet: false,
                    iconName: '/assets/icons/company-intuitive.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/testimonials/ryan-steger',
                },
            ] as CardModel[]);
        this.loadingStateService?.stop();
    }
}
