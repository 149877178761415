import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './a-title.component';

@NgModule({
    declarations: [TitleComponent],
    imports: [CommonModule],
    exports: [TitleComponent],
})
export class TitleModule {}
