<div
    class="m-footer-item"
    [ngClass]="[containerClass ? containerClass : '']">
    <ul
        *ngIf="footerLinks"
        [ngClass]="{
            'border-color-top border-color-bottom': hasBorder,
            'hide-mobile': hideMobile,
            'footer-nav': true
        }">
        <ng-container *ngFor="let item of footerLinks">
            <li class="nav-item d-flex align-items-center">
                <a-icon
                    style="margin-right: 1rem"
                    [white]="true"
                    *ngIf="item.icon"
                    [source]="item.icon"></a-icon>
                <a-link
                    [isExternal]="item.isExternal"
                    [url]="item.url"
                    [target]="item.target"
                    componentClass="link footer-link-color"
                    class="w-100">
                    <span [innerHtml]="getbtnLabel(item.label)"></span>
                </a-link>
            </li>
        </ng-container>
    </ul>
</div>
