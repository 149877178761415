<div
    [ngClass]="[
        'm-section-title row',
        align === 'right' ? 'align-right' : '',
        align === 'center' ? 'align-center text-center' : '',
        align === 'titleOnLeft' ? 'title-on-left' : '',
        align === 'titleOnRight' ? 'title-on-right' : '',
        componentClass ? componentClass : ''
    ]"
    data-aos="fade-up"
    data-aos-delay="10"
    data-aos-once="true">
    <h5 class="half-title m-0">{{ halfTitle }}</h5>
    <h2
        [ngClass]="{
            'title': true,
            'w-100': w100,
            'contrast-color': lightColor,
            'title-no-wrap': titleNoWrap
        }"
        *ngIf="type !== 'onlyDesc'"
        [innerHtml]="title ?? '' | translate"
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-once="true"></h2>
    <ng-content select="[title]"></ng-content>
    <a-paragraph
        [ngClass]="{
            paragraph: true,
            'w-100': w100
        }"
        *ngIf="type !== 'onlyTitle'"
        [content]="desc ?? '' | translate"
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-once="true"
        [contrast]="lightColor"></a-paragraph>
</div>
