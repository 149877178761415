import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorComponent } from './m-language-selector.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [LanguageSelectorComponent],
    imports: [CommonModule, TranslateModule, NgbDropdownModule],
    exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
