import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScalioVideoSectionModule } from '../../components/atomic/organisms/o-scalio-video-section/o-scalio-video-section.module';
import { CardsModule } from '../../@shared/atomic/organisms/o-cards/o-cards.module';
import { SectionTitleModule } from '../../@shared/atomic/molecules/m-section-title/m-section-title.module';
import { CardsItemModule } from '../../@shared/atomic/molecules/m-cards-item/m-cards-item.module';
import { CtaBannerModule } from '../../@shared/atomic/molecules/m-cta-banner/m-cta-banner.module';
import { ImageSectionModule } from '../../@shared/atomic/organisms/o-image-section/o-image-section.module';
import { BgImageSectionModule } from './../../components/atomic/organisms/o-bg-image-section/o-bg-image-section.module';
import { ImageSectionItemModule } from '../../@shared/atomic/molecules/m-image-section-item/m-image-section-item.module';
import { LogoSliderModule } from '../../@shared/atomic/organisms/o-logo-slider/o-logo-slider.module';
import { ImageModule } from '../../@shared/atomic/atoms/a-image/a-image.module';
import { ImageCarouselSectionModule } from '../../@shared/atomic/organisms/o-image-carousel-section/o-image-carousel-section.module';
import { BoxImageSectionModule } from '../../@shared/atomic/organisms/o-box-image-section/o-box-image-section.module';
import { BoxImageItemModule } from '../../@shared/atomic/molecules/m-box-image-item/m-box-image-item.module';
import { ParallaxCardItemModule } from './../../components/atomic/molecules/m-parallax-card-item/m-parallax-card-item.module';
import { LinkModule } from '@src/app/@shared/atomic/atoms/a-link/a-link.module';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        NgbCarouselModule,
        ScalioVideoSectionModule,
        CardsModule,
        SectionTitleModule,
        CardsItemModule,
        CtaBannerModule,
        ImageSectionModule,
        BgImageSectionModule,
        ImageSectionItemModule,
        LogoSliderModule,
        ImageModule,
        ImageCarouselSectionModule,
        BoxImageSectionModule,
        BoxImageItemModule,
        AngularSvgIconModule,
        ParallaxCardItemModule,
        LinkModule,
    ],
})
export class HomeModule {}
