import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState, StateKey } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { PlatformService } from '../utils/platform/platform.service';

@Injectable({
    providedIn: 'root',
})
export class HttpStateClient extends HttpClient {
    private isServer: boolean = false;
    constructor(handler: HttpHandler, private state: TransferState, private platform: PlatformService) {
        super(handler);
    }

    getWithTransferState<T>(stateKey: string, url: string, opt?: any): Observable<T> {
        return this.getWithServerTransfer<any>(stateKey, this.get(url, opt));
    }

    saveState(stateKey: string, data: any, resultKey: StateKey<any>) {
        if (this.platform.isServer) {
            this.state.set(resultKey, {
                [stateKey]: data,
            });
        }
    }

    saveStateByKey(stateKey: string, data: any) {
        if (this.platform.isServer) {
            const resultKey = makeStateKey<any>(stateKey);
            this.state.set(resultKey, {
                [stateKey]: data,
            });
        }
    }

    private getWithServerTransfer<T>(stateKey: string, observable: Observable<T>): Observable<any> {
        const RESULT_KEY = makeStateKey<any>(stateKey);
        if (this.state.hasKey(RESULT_KEY)) {
            const result = this.state.get(RESULT_KEY, {});
            return of(result[stateKey]);
        } else {
            return observable.pipe(
                tap((data) => {
                    this.saveState(stateKey, data, RESULT_KEY);
                }),
            );
        }
    }

    getFromBackend(backendParams: IBackendParams) {
        let { url, params, transferState = false } = backendParams;
        let args: any = { params };

        //In the backend it is usual to have repeated parameters. See the associations case.
        if (Object.values(params).some((key) => Array.isArray(params))) {
            const queryString = Object.entries(params)
                .map((k, v) => {
                    if (Array.isArray(v)) {
                        let result = '';
                        v.forEach((value, index) => {
                            result = result.concat(index === 0 ? `${k}=${value}` : `&${k}=${value}`);
                        });
                        return result;
                    } else {
                        return `${k}=${v}`;
                    }
                })
                .join('&');
            url = url.concat(`?${queryString}`);
            args = {};
        }

        if (transferState) {
            const module = url.replace('/', '-');
            const values = Object.values(params).join('-');
            return this.getWithTransferState(`state-${module}-${values}`, url, args) as Observable<any>;
        } else {
            return this.get(url, args) as Observable<any>;
        }
    }
}

export interface IBackendParams {
    url: string;
    params: any;
    transferState?: boolean;
}
