import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpStateClient } from '../@shared/http/http-client-state';
import { AppSettings } from '../app.settings';
import { map } from 'rxjs/operators';
import { Category } from '../@shared/cms/models/categories.model';
import { CMSList } from '../@shared/cms/models/cms-list.model';

type EventPastFilter = 'past' | 'future' | 'none';

@Injectable({
    providedIn: 'root',
})
export class PagesService {
    constructor(private httpService: HttpStateClient) {}

    //******************* Associations *******************
    getAssociations(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'api/associations', params, transferState });
    }

    //******************* Blogs *******************
    getBlogInformation(slug?: string) {
        const args = { sort: 'position', page: 1, limit: AppSettings.BLOG_PAGE_SIZE };

        return forkJoin({
            categories: this.getCategories({ sort: 'timeCreated', limit: 0 }),
            groups: this.getCategoryGroups({ sort: 'position', type: 'blog' }),
            blogList: slug ? this.getBlogsByGroup(slug) : this.getBlogs(args),
        });
    }

    getBlogsByGroup(slug: string, params: any = {}) {
        return this.getBlogsBySuffix(params, `/category/group/${slug}/paged`);
    }

    getBlogs(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/blog', params, transferState });
    }

    getBlogsBySuffix(params: any, suffix: string, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: `papi/blog${suffix}`, params, transferState });
    }

    getBlogBySlug(slug: string, params: any, transferState: boolean = false) {
        if (!params.slug) params = { ...params, ...{ slug } };
        return this.httpService.getFromBackend({ url: `papi/blog/category/${slug}/paged`, params, transferState });
    }

    getBlogDetailsPage(slug: string) {
        return forkJoin({
            blogDetail: this.getBlogDetails({ slug }),
            categories: this.getCategories({ sort: 'timeCreated', limit: 0 }),
        });
    }

    getBlogDetails(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/blog', params, transferState });
    }

    //******************* Categories *******************
    getCategories(params: any, transferState: boolean = false) {
        if (params && params.sort && params.sort.includes('weight')) {
            return this.httpService.getFromBackend({ url: 'api/categories', params, transferState }).pipe(
                map((results: CMSList<Category>) => {
                    return {
                        pages: results.pages,
                        items: results.items,
                        data: results.data.sort((a: any, b: any) => {
                            const _a = a.weight ? a.weight : 0;
                            const _b = b.weight ? b.weight : 0;
                            if (params.sort.includes('-')){
                                if (_a === _b) {
                                    return b.name.localeCompare(a.name);
                                }
                                return _b > _a ? -1 : 1;
                            } else {
                                if (_a === _b) {
                                    return a.name.localeCompare(b.name);
                                }
                                return _a > _b ? -1 : 1;
                            }

                        }),
                    };
                }),
            );
        } else {
            return this.httpService.getFromBackend({ url: 'api/categories', params, transferState });
        }
    }

    getCategoriesBySuffix(params: any, suffix: string, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: `api/categories${suffix}`, params, transferState });
    }

    getCategoryGroups(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/category-groups', params, transferState });
    }

    //******************* Contact *******************
    postContact(data: any) {
        return this.httpService.post('api/contact', data);
    }

    //******************* Events *******************
    getEventsPage() {
        return forkJoin({
            eventsFuture: this.getEventsByPageNumber('future', 1, 0),
            eventsPast: this.getEventsByPageNumber('past', 1),
        });
    }

    getEventDetailsPage(slug: string) {
        return forkJoin({
            eventDetail: this.getEvents({ slug }),
            categories: this.getCategories({ sort: 'timeCreated', limit: 0 }, true),
        });
    }

    getEventsByPageNumber(
        past: EventPastFilter,
        pageNumber: number,
        pageSize: number = AppSettings.PAGE_SIZE,
    ): Observable<any> {
        let params = {
            sort: '-startDate',
            page: pageNumber,
            limit: pageSize,
        };

        if (past !== 'none') params = { ...params, ...{ past: past === 'past' } };
        return this.getEvents(params);
    }

    getEvents(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/events', params, transferState });
    }

    //******************* FAQ *******************
    getFaqs(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/faqs', params, transferState });
    }

    //******************* Industries *******************
    getIndustries(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/industries', params, transferState });
    }

    //******************* Jobs *******************
    postJobsApplication(data: any, id: string) {
        return this.httpService.post(`api/jobs/${id}/application`, data);
    }

    getJobsPage() {
        return forkJoin({
            categories: this.getCategories({ sort: 'name', limit: 0 }),
            careerList: this.getJobs({ sort: 'position', limit: 0 }),
        });
    }

    getJobs(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/jobs', params, transferState });
    }

    getJobDetailsPage(id: number) {
        return forkJoin({
            jobDetail: this.httpService.getFromBackend({
                url: `papi/jobs/${id}`,
                params: { sort: 'timeCreated', limit: 0 },
                transferState: true,
            }),
            categories: this.getCategories({ sort: 'name', limit: 0 }, true),
        });
    }

    getJobPartner(
        page: number,
        withWork: boolean,
        term?: string,
        date?: string,
        associations?: string[],
    ): Observable<any> {
        let params = {
            withWork,
            page,
            limit: AppSettings.PAGE_SIZE,
            sort: 'timeCreated',
        };

        if (term) params = { ...params, ...{ search: term } };
        if (date) params = { ...params, ...{ timePublished: date } };
        if (associations) params = { ...params, ...{ work: associations } };

        return this.httpService.getFromBackend({ url: 'papi/jobs/profile', params, transferState: false });
    }

    //******************* Logos *******************
    getLogos(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/logo', params, transferState });
    }

    //******************* Policies *******************
    getPolicies(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/legaldoc', params, transferState });
    }

    //******************* Services *******************
    getServices(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/services', params, transferState });
    }

    getServicesPage(){
        return forkJoin({
            categories: this.getCategories({ sort: '-weight', limit: 0 }),
            services: this.getServices({ sort: 'position', page: 1, limit: 0 }),
        });
    }

    //******************* Teams *******************
    getTeamPage() {
        return forkJoin({
            categories: this.getCategories({ sort: 'weight', limit: 0 }),
            teamMembers: this.getTeams({ sort: 'position', limit: 0 }),
        });
    }

    getTeams(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/team', params, transferState });
    }

    //******************* Testimonials *******************
    getTestimonialPage() {
        return forkJoin({
            testimonials: this.getTestimonials({ sort: 'position', limit: 0 }),
            works: this.getWorks({ sort: 'position', limit: 0 }),
        });
    }

    getTestimonials(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/testimonials', params, transferState });
    }

    //******************* Works *******************
    getWorkInformation(
        slug?: string,
        isGroup?: boolean,
        args: any = { sort: 'position', page: 1, limit: AppSettings.BLOG_PAGE_SIZE },
    ) {
        return forkJoin({
            categories: this.getCategories({ sort: 'timeCreated', limit: 0 }),
            groups: this.getCategoryGroups({ sort: 'position', type: 'work' }),
            workList: this.getWorkList(slug, isGroup, args),
        });
    }

    getWorkList(slug?: string, isGroup?: boolean, args: any = { sort: 'position', page: 1, limit: 0 }) {
        return slug
            ? isGroup
                ? this.getWorksByGroup(slug, args)
                : this.getWorksByCategory(slug, args)
            : this.getWorks(args);
    }

    getWorkPage(slug: string) {
        return forkJoin({
            categories: this.getCategories({ sort: 'timeCreated', limit: 0 }),
            work: this.getWorks({ slug }),
        });
    }

    getWorksByGroup(slug: string, params: any = {}) {
        return this.getWorksBySuffix(params, `/category/group/${slug}/paged`);
    }

    getWorksByCategory(slug: string, params: any = {}) {
        return this.getWorksBySuffix(params, `/category/${slug}/paged`);
    }

    getWorks(params: any, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: 'papi/work', params, transferState });
    }

    getWorksBySuffix(params: any, suffix: string, transferState: boolean = false) {
        return this.httpService.getFromBackend({ url: `papi/work${suffix}`, params, transferState });
    }

    getWorksInitiativesPage(){
        return forkJoin({
            categories: this.getCategories({ sort: 'weight', limit: 0 }),
            workList: this.getWorks({ sort: 'position', page: 1, limit: 0 }),
        });
    }
}
