import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'a-title',
    templateUrl: './a-title.component.html',
    styleUrls: ['./a-title.component.scss'],
})
export class TitleComponent implements OnInit {
    @Input()
    title1!: string;

    @Input()
    title2!: string;

    @Input()
    title3!: string;

    @Input()
    title4!: string;

    @Input()
    title5!: string;

    @Input()
    regular!: boolean;

    @Input()
    gradient!: boolean;

    @Input()
    bold!: boolean;

    @Input()
    noBottom!: boolean;

    @Input()
    truncate!: boolean;

    @Input()
    mb5!: boolean;

    @Input()
    textCenter!: boolean;

    @Input()
    titleContrast!: string;

    @Input()
    contrast!: boolean;

    @Input() containerClass?: string;

    constructor() {}

    ngOnInit(): void {}
}
