import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@app/@shared/services/theme.service';

@Component({
    selector: 'a-theme-toggle',
    templateUrl: './a-theme-toggle.component.html',
    styleUrls: ['./a-theme-toggle.component.scss'],
})
export class ThemeToggleComponent implements OnInit {
    @Input() containerClass?: string;
    theme: string = 'bootstrap';

    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {}

    toggleTheme() {
        if (this.theme === 'bootstrap') {
            this.theme = 'bootstrap-dark';
        } else {
            this.theme = 'bootstrap';
        }

        this.themeService.setTheme(this.theme);
    }
}
