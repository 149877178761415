import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TemplateRef, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from '@app/@shared/services/theme.service';
import { environment } from '@src/environments/environment';
import { CredentialsService } from '../../../cms/auth/credentials.service';
import { MessageService } from '../../../utils/message/message.service';
import { MessageServiceModel } from '../../../utils/message/message.model';
import { ModalParam } from '../../molecules/m-modal/m-modal.model';
import { NavigationModel } from '../../molecules/m-navbar-item/m-navbar-item.model';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
    selector: 'o-navbar',
    templateUrl: './o-navbar.component.html',
    styleUrls: ['./o-navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnChanges {
    @ViewChild('nav') navbar!: ElementRef;
    @ViewChild('modalmenu') modalContent!: TemplateRef<any>;
    @Output() isOpenChange: EventEmitter<ModalParam> = new EventEmitter();
    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (this.isBrowser && this.navbar && this.navbar.nativeElement) {
            let element = this.navbar.nativeElement;
            if (window.pageYOffset > 0) {
                element.classList.add('navbar-on-scroll');
            } else {
                element.classList.remove('navbar-on-scroll');
            }
        }
    }

    @Input() navItems: NavigationModel[] = [];
    @Input() copyItems: Array<any> = [];
    @Input() showThemeToggle: boolean = false;
    @Input() hasFrame: boolean = true;
    @Input() componentClass?: string;
    @Input() hideInRoutes: string[] = [];
    showComponent: boolean = true;
    button?: NavigationModel;
    buttonMobile?: NavigationModel;
    items!: NavigationModel[];
    logoUrl: string = `${environment.baseUrl}/assets/icons/logo.svg`;
    lightLogoUrl: string = `${environment.baseUrl}/assets/icons/logo.svg`;
    iconClose: string = `${environment.baseUrl}/assets/icons/icon-close.svg`;
    private isBrowser: boolean;
    private isServer: boolean;

    constructor(
        private router: Router,
        private credentialsService: CredentialsService,
        private message: MessageService,
        private modalService: NgbModal,
        private themeService: ThemeService,
        private renderer: Renderer2,
        private translateService: TranslateService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.isServer = isPlatformServer(platformId);
    }

    ngOnInit(): void {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.showComponent = !this.hideInRoutes?.includes(val.url);
            }
        });
        this.showComponent = !this.hideInRoutes?.includes(this.router.url);
        if (this.navItems) {
            this.addNavigationItems(this.navItems);

            this.message.getMessage().subscribe((msj: MessageServiceModel) => {
                if (msj.type === 'updateUserDetail') this.addNavigationItems(this.navItems);
            });
        }

        if (this.isServer) return;

        this.themeService.themeChanges().subscribe((theme) => {
            if (theme.oldValue) {
                this.renderer.removeClass(document.body, theme.oldValue);
            }
            this.renderer.addClass(document.body, theme.newValue);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.navItems || !changes.navItems.currentValue) return;

        this.addNavigationItems(changes.navItems.currentValue);
    }

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }

    addNavigationItems(navItems: NavigationModel[]) {
        const isAuth = this.credentialsService.isAuthenticated();
        if (!isAuth) {
            const itemBtn = navItems.filter((item: NavigationModel) => item.isBtn);
            if (itemBtn) {
                const btnDesktop = itemBtn.filter((item: NavigationModel) => item.hideMobile);
                if (btnDesktop) this.button = btnDesktop[0];
                const btnMobile = itemBtn.filter((item: NavigationModel) => item.hideDesktop);
                if (btnMobile) this.buttonMobile = btnMobile[0];
            }
        } else {
            this.button = undefined;
        }

        this.items = navItems.filter((item: NavigationModel) => {
            if (item.hideAuth) return isAuth ? false : true;
            else if (item.hideNoAuth) return isAuth ? true : false;
            else return true;
        });
    }

    click(close: boolean = false) {
        if (this.button) {
            if (this.button.isExternal) {
                var check = this.button.url.substring(0, 4);
                if (check == 'http') {
                    window.open(this.button.url, this.button.target);
                } else {
                    window.open('//' + this.button.url, this.button.target);
                }
            } else {
                this.router.navigateByUrl(this.button.url);
            }
        }

        if (close) this.closeMenu();
    }

    closeMenu() {
        this.modalService.dismissAll('close');
    }

    openMenu() {
        this.modalService.open(this.modalContent, { windowClass: 'fullscreen-modal' }).result.then(
            (result) => {
                this.isOpenChange.emit({ closeResult: `Closed with: ${result}`, open: false });
            },
            (reason) => {
                this.isOpenChange.emit({ closeResult: `Dismissed ${this.getDismissReason(reason)}`, open: false });
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
