import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouteReusableStrategy, ApiPrefixInterceptor, ErrorHandlerInterceptor } from '@shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoadingStateService } from './services/loading-state.service';
import { CacheInterceptor } from './@shared/http/cache.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrowserStateInterceptor } from './@shared/http/browser-state.interceptor';
import { LoaderContainerModule } from './@shared/atomic/molecules/m-loader-container/m-loader-container.module';
import { environment } from '@src/environments/environment';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppSettings } from './app.settings';

export let AppInjector: Injector;

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        HttpClientModule,
        TransferHttpCacheModule,
        RouterModule,
        TranslateModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        LoaderContainerModule,
        ShellModule,
        PagesModule,
        AppRoutingModule, // must be imported as the last module as it contains the fallback route
    ],
    declarations: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiPrefixInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy,
        },
        { provide: 'environment', useValue: environment },
        { provide: 'AppSettings', useValue: AppSettings },
        LoadingStateService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
