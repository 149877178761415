import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BgImageSectionComponent } from './o-bg-image-section.component';
import { ParallaxModule } from 'ngx-parallax';
import { ImageModule } from './../../../../@shared/atomic/atoms/a-image/a-image.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    declarations: [BgImageSectionComponent],
    imports: [CommonModule, TranslateModule, ImageModule, ParallaxModule, LazyLoadImageModule],
    exports: [BgImageSectionComponent],
})
export class BgImageSectionModule {}
