import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderContainerComponent } from './m-loader-container.component';
import { LoaderModule } from '../../atoms/a-loader/a-loader.module';

@NgModule({
    declarations: [LoaderContainerComponent],
    imports: [CommonModule, LoaderModule],
    exports: [LoaderContainerComponent],
})
export class LoaderContainerModule {}
