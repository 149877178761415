<div
    *ngIf="languages.length > 1"
    class="m-language-selector"
    [class.nav-item]="inNavbar"
    [ngClass]="[containerClass ? containerClass : '']"
    ngbDropdown>
    <div class="hide-mobile">
        <ul class="navbar-nav">
            <li
                class="nav-item"
                ngbDropdown
                [open]="isOpen"
                (mouseleave)="isOpen = false"
                (mouseover)="isOpen = true">
                <a
                    *ngIf="inNavbar; else button"
                    id="language-dropdown"
                    class="nav-link"
                    ngbDropdownToggle>
                    {{ currentLanguage!.text | translate }}
                </a>

                <div>
                    <ng-template #button>
                        <div
                            id="language-dropdown"
                            [ngClass]="{
                                'nav-link': true,
                                'is-open': isOpen
                            }"
                            ngbDropdownToggle>
                            <i class="fas fa-globe"></i>
                            {{ currentLanguage!.text }}
                        </div>
                    </ng-template>
                    <div
                        ngbDropdownMenu
                        aria-labelledby="language-dropdown"
                        [ngClass]="menuClass">
                        <button
                            class="dropdown-item"
                            *ngFor="let item of languages"
                            (click)="setLanguage(item.language)">
                            {{ item.text | translate }}
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div
        *ngIf="label"
        class="hide-desktop navbar-nav">
        <div class="d-flex align-items-center nav-link">
            <i class="fas fa-globe"></i>
            <p
                class="ml-2 mb-0"
                [innerHtml]="label | translate"></p>
            <p
                class="ml-2 mb-0"
                [innerHtml]="currentLanguage!.text"></p>
        </div>
        <div aria-labelledby="language-dropdown">
            <div
                class="dropdown-item nav-link"
                *ngFor="let item of languages"
                (click)="setLanguage(item.language)">
                <p
                    class="nav-link mb-0"
                    [innerHtml]="item.text | translate"></p>
            </div>
        </div>
    </div>
</div>
