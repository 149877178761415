import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Shell } from '@app/shell/shell.service';
import { HomeComponent } from './home.component';
import { HumanRightsModule } from '../human-rights/human-rights.module';

const routes: Routes = [
    Shell.childRoutes([
        {
            path: '',
            component: HomeComponent,
            data: { title: marker('Home') },
        },
        {
            path: 'work/:slug',
            loadChildren: () =>
                import('./../our-work-detail/our-work-detail.module').then((m) => m.OurWorkDetailModule),
        },
        {
            path: 'work',
            loadChildren: () => import('./../our-work/our-work.module').then((m) => m.OurWorkModule),
        },
        {
            path: 'work/group/:slug',
            loadChildren: () => import('./../our-work/our-work.module').then((m) => m.OurWorkModule),
        },
        {
            path: 'work/category/:slug',
            loadChildren: () => import('./../our-work/our-work.module').then((m) => m.OurWorkModule),
        },
        {
            path: 'contact',
            loadChildren: () => import('./../contact/contact.module').then((m) => m.ContactModule),
        },
        {
            path: 'events',
            loadChildren: () => import('./../events/events.module').then((m) => m.EventsModule),
        },
        {
            path: 'events/:slug',
            loadChildren: () => import('../events-detail/events-detail.module').then((m) => m.EventsDetailModule),
        },
        {
            path: 'blog',
            loadChildren: () => import('./../blog/blog.module').then((m) => m.BlogModule),
        },
        {
            path: 'blog/:slug',
            loadChildren: () => import('./../blog-detail/blog-detail.module').then((m) => m.BlogDetailModule),
        },
        {
            path: 'blog/group/:slug',
            loadChildren: () => import('./../blog/blog.module').then((m) => m.BlogModule),
        },
        {
            path: 'team',
            loadChildren: () => import('./../team/team.module').then((m) => m.TeamModule),
        },
        {
            path: 'careers',
            loadChildren: () => import('./../jobs/jobs.module').then((m) => m.JobsModule),
        },
        {
            path: 'careers/:slug',
            loadChildren: () => import('./../jobs/jobs.module').then((m) => m.JobsModule),
        },
        {
            path: 'policy/:slug',
            loadChildren: () => import('./../policy/policy.module').then((m) => m.PolicyModule),
        },
        {
            path: 'testimonials',
            loadChildren: () => import('./../testimonial/testimonial.module').then((m) => m.TestimonialModule),
        },
        {
            path: 'testimonials/:slug',
            loadChildren: () => import('./../testimonial/testimonial.module').then((m) => m.TestimonialModule),
        },
        {
            path: 'industries',
            loadChildren: () => import('./../industries/industries.module').then((m) => m.IndustriesModule),
        },
        {
            path: 'about',
            loadChildren: () => import('./../about/about.module').then((m) => m.AboutModule),
        },
        {
            path: 'services',
            loadChildren: () => import('./../services/services.module').then((m) => m.ServicesModule),
        },
        {
            path: 'initiatives',
            loadChildren: () => import('./../initiatives/initiatives.module').then((m) => m.InitiativesModule),
        },
        {
            path: 'initiatives/investments',
            loadChildren: () => import('./../investments/investments.module').then((m) => m.InvestmentsModule),
        },
        {
            path: 'initiatives/open-source',
            loadChildren: () => import('./../open-source/open-source.module').then((m) => m.OpenSourceModule),
        },
        {
            path: 'initiatives/human-rights',
            loadChildren: () => import('./../human-rights/human-rights.module').then((m) => m.HumanRightsModule),
        },
        { path: '**', redirectTo: '', pathMatch: 'full' },
    ]),
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class HomeRoutingModule {}
