import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BoxImageSectionComponent } from './o-box-image-section.component';
import { LinkModule } from '../../atoms/a-link/a-link.module';
import { NoDataContentModule } from '../../molecules/m-no-data-content/m-no-data-content.module';

@NgModule({
    declarations: [BoxImageSectionComponent],
    imports: [CommonModule, TranslateModule, LinkModule, NoDataContentModule],
    exports: [BoxImageSectionComponent],
})
export class BoxImageSectionModule {}
