import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ArgumentsService {
    private obj: any[] = [];
    constructor() {}

    sendArg(key: string, value: any = null) {
        this.obj = [
            ...this.obj,
            {
                key,
                value,
            },
        ];
    }

    getArg(key: string, removeit: boolean = true): string {
        const index = this.obj.findIndex((x) => x.key === key);
        if (index === -1) return '';
        const result = this.obj[index].value;
        if (removeit) this.obj.splice(index, 1);
        return result;
    }
}
