<div class="home-page">
    <o-scalio-video-section
        halfTitle="Half Title"
        title="Tech forward.<br> Human focused."
        [content]="content"
        btnLabel="Primary"
        btnLink="/"
        btnClassName="btn btn-primary"
        btnSecondaryLabel="Secondary"
        btnSecondaryLink="/"
        btnSecondaryClassName="btn btn-outline-primary"
        [video]="headerVideoBlock"
        [overlay]="true"
        [hasVideo]="true"
        [isPlaying]="isPlaying">
        <ng-container logos>
            <div class="header-logo-container">
                <div class="container-fluid pt-0 pb-0 mb-5">
                    <div class="d-flex align-items-center justify-content-between">
                        <h4 class="zindex-offcanvas contrast-color m-0 hide-mobile">Trusted by</h4>
                        <div class="trusted-logos zindex-offcanvas">
                            <svg-icon
                                class="hide-mobile"
                                src="/assets/img/logo-sheet-white-single.svg"></svg-icon>
                            <svg-icon
                                class="hide-desktop"
                                src="/assets/img/logo-sheet-white-double.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </o-scalio-video-section>
    <o-cards
        containerClass="container-xxl py-7"
        id="nextSection">
        <ng-container header>
            <m-section-title
                class="row mb-5"
                halfTitle="Building tomorrow's products & platforms"
                title="We are a global technology and creative agency."
                type="onlyTitle"
                [align]="'left'"
                [w100]="true"></m-section-title>
        </ng-container>
        <ng-container items>
            <div
                *ngFor="let item of cardsGlobalTech"
                class="col-md-6 d-flex align-items-stretch mb-3">
                <m-parallax-card-item
                    [parallaxContent]="item.parallaxContent"
                    [title]="item.title"
                    [subTitle]="item.subTitle"
                    [content]="item.content"></m-parallax-card-item>
            </div>
        </ng-container>
    </o-cards>
    <o-bg-image-section
        bgImage="/assets/img/healthtech.jpg"
        containerClass="container-xxl"
        [overlay]="true"
        [parallaxValue]="-250"
        [parallaxSpeed]="0.15">
        <ng-container content>
            <div class="row">
                <div class="col-lg-5 hide-mobile p-0"></div>
                <div class="col-lg-7 contrast-color p-0">
                    <p
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                        class="half-title">
                        Inspiring Innovation
                    </p>
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true">
                        Products and services for every industry
                    </h2>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true">
                        AR / Medical Tech / Digital Marketing SaaS / Machine Learning / AI Dashboards / Financial Tech
                    </p>
                    <a-link
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-once="true"
                        componentClass="btn btn-primary">
                        <span>See Our Work</span>
                    </a-link>
                </div>
            </div>
        </ng-container>
    </o-bg-image-section>
    <o-cards containerClass="container-xxl py-7 innovative-products">
        <ng-container header>
            <m-section-title
                class="row"
                halfTitle="What we do"
                title="Innovative products delivering engaging experiences at scale."
                desc="We've designed, architected and scaled hundreds of mobile and web applications including numerous top-ten mobile applications, high-trafficked web systems, and big-data architectures for venture-backed startups and Fortune 100 companies"
                [align]="'left'"
                [w100]="true"></m-section-title>
        </ng-container>
        <ng-container items>
            <div
                *ngFor="let item of innovativeProducts"
                class="col-md-4 p-0">
                <m-cards-item
                    class="w-100"
                    [iconSet]="true"
                    [iconName]="item.icon"
                    [title]="item.title"></m-cards-item>
            </div>
        </ng-container>
    </o-cards>
    <div class="gradient-background tested-quality">
        <o-cards
            [cards]="cardsWithLinkCarousel"
            [cardsPerRow]="3"
            idCarousel="carousel"
            containerClass="container-fluid py-7"
            colClass="col-md-4">
            <ng-container header>
                <m-section-title
                    class="row"
                    title="Acclaimed technology. Unmatched innovation. Tested quality."
                    type="onlyTitle"
                    [align]="'center'"
                    [lightColor]="true"
                    [w100]="true"></m-section-title>
            </ng-container>
        </o-cards>
    </div>
    <o-bg-image-section
        containerClass="container-xxl py-7"
        bgImage="/assets/img/opensourced.jpg"
        [overlay]="true"
        [parallaxValue]="-450"
        [parallaxSpeed]="0.1">
        <ng-container content>
            <div class="row">
                <div class="col-lg-7 contrast-color p-0">
                    <p
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                        class="half-title">
                        What we love
                    </p>
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true">
                        Open-sourced, never outsourced.
                    </h2>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true">
                        When we're not busy creating software for our clients, we love building products of our own and
                        contributing to the open source community.
                    </p>
                    <a-link
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-once="true"
                        componentClass="btn btn-primary">
                        <span>See Our Creations</span>
                    </a-link>
                </div>
                <div class="col-lg-5 hide-mobile p-0"></div>
            </div>
        </ng-container>
    </o-bg-image-section>
    <div class="grey-background">
        <o-logo-slider
            containerClass="container-xxl pt-7 pb-0"
            [hasLogos]="true"
            [hasLogos2]="true"
            [hasLogos3]="true"
            [greyBackground]="true"
            [greyImages]="true">
            <ng-container header>
                <m-section-title
                    halfTitle="Who we are"
                    title="<div class='col-md-10 p-0'>Representing Silicon Valley's most innovative companies</div>"
                    desc="<div class='col-md-10 p-0'>A close-knit team of engineers, designers and entrepreneurs who grew their professional careers at Silicon Valley's leading companies. <a class='brand-color' href='/'>Meet our team</a></div>"
                    type="showAll"
                    [align]="'left'"></m-section-title>
            </ng-container>
            <ng-container logos>
                <img
                    src="/assets/img/logo-sheet-color-row1.svg"
                    alt="scalio" />
            </ng-container>
            <ng-container logos2>
                <img
                    src="/assets/img/logo-sheet-color-row2.svg"
                    alt="scalio" />
            </ng-container>
            <ng-container logos3>
                <img
                    src="/assets/img/logo-sheet-color-row3.svg"
                    alt="scalio" />
            </ng-container>
        </o-logo-slider>
    </div>
    <o-bg-image-section
        containerClass="container-xxl"
        bgImage="/assets/img/creative.jpg"
        [overlay]="true"
        [parallaxValue]="-600"
        [parallaxSpeed]="0.1">
        <ng-container content>
            <div class="row">
                <div class="col-lg-5 col-sm-12 hide-mobile p-0"></div>
                <div class="col-lg-7 col-sm-12 contrast-color p-0">
                    <p
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                        class="half-title">
                        What we create
                    </p>
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true">
                        A full offering of creative services
                    </h2>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once="true">
                        Leverage the most contemporary design practices and level-up your product. From gamification and
                        UI/UX design to consumer insights and interactive prototyping, we'll help you de-risk your
                        product decisions and bring home the ROI.
                    </p>
                    <a-link
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-once="true"
                        componentClass="btn btn-primary">
                        <span>Let's Inspire</span>
                    </a-link>
                </div>
            </div>
        </ng-container>
    </o-bg-image-section>
    <m-cta-banner
        title="Let us show you what we can do."
        btnClassName="btn btn-outline-light"
        btnLabel="Get Started"
        href="/contact"></m-cta-banner>
</div>
