import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FooterItems } from './o-footer.model';

@Component({
    selector: 'o-footer',
    templateUrl: './o-footer.component.html',
    styleUrls: ['./o-footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() footerItems: FooterItems[] = [];
    @Input() copyItemsLeft: Array<any> = [];
    @Input() copyItemsRight: Array<any> = [];
    @Input() colClass?: string;
    @Input() title!: string;
    @Input() containerClass?: string;
    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {}

    get colClassCss(): String {
        return this.colClass ?? '';
    }

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }
}
