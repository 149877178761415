import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterItemComponent } from './m-footer-item.component';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { LinkModule } from '../../atoms/a-link/a-link.module';

@NgModule({
    declarations: [FooterItemComponent],
    imports: [CommonModule, TranslateModule, IconModule, LinkModule],
    exports: [FooterItemComponent],
})
export class FooterItemModule {}
