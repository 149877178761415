import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'm-avatar',
    templateUrl: './m-avatar.component.html',
    styleUrls: ['./m-avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() imgSrc?: string;
    @Input() title!: string;
    @Input() desc?: string;
    @Input() textHasBreak: boolean = true;
    @Input() lightColor: boolean = false;
    @Input() componentClass: string | undefined = '';

    constructor() {}

    ngOnInit(): void {}
}
