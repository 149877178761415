<footer
    class="o-footer position-relative"
    [ngClass]="[containerClass ? containerClass : '']">
    <div class="container-fluid py-7">
        <ng-content select="[header]"></ng-content>
        <a-title
            [contrast]="true"
            [title3]="title | translate"></a-title>
        <div [ngClass]="['d-grid', colClassCss]">
            <ng-content select="[containerLeftSide]"></ng-content>
            <div *ngFor="let footerItem of footerItems">
                <div>
                    <p
                        *ngIf="footerItem.category"
                        [innerHtml]="footerItem.category"
                        class="text-white category-footer"></p>
                    <m-footer-item
                        [containerClass]="footerItem.containerClass"
                        [hideMobile]="footerItem.hideMobile"
                        [hasBorder]="footerItem.hasBorder"
                        [footerLinks]="footerItem.items"></m-footer-item>
                </div>
            </div>
            <ng-content select="[containerRightSide]"></ng-content>
        </div>
    </div>
    <div class="copyright">
        <div class="container-fluid content pt-0">
            <div class="left-content social-logos">
                <ng-container *ngFor="let item of copyItemsLeft">
                    <div class="me-1">
                        <a
                            *ngIf="item.href"
                            [href]="item.href"
                            [target]="item.target"
                            [attr.aria-label]="item.alt"
                            componentClass="link contrast-color regular-weight">
                            <h5
                                *ngIf="item.label"
                                [innerHtml]="getbtnLabel(item.label)"
                                class="mr-3"></h5>
                            <a-icon
                                *ngIf="item.iconSrc"
                                [source]="item.iconSrc"></a-icon>
                        </a>
                    </div>
                </ng-container>
            </div>
            <div class="right-content">
                <iframe
                    title="Facebook"
                    src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FScalioLabs%2F&width=178&layout=button_count&action=recommend&size=small&show_faces=true&share=true&height=46&appId"
                    width="220"
                    height="30"
                    style="border: none; overflow: hidden"
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allow="encrypted-media"></iframe>
                <ng-container *ngFor="let item of copyItemsRight">
                    <p
                        [innerHtml]="getbtnLabel(item.label)"
                        *ngIf="!item.href"
                        class="text-white h5 m-0"></p>
                </ng-container>
            </div>
            <ng-content select="[copyItemsRightSide]"></ng-content>
        </div>
        <ng-content select="[copyItemsEnd]"></ng-content>
    </div>
</footer>
