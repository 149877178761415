<div
    class="o-cards"
    [ngClass]="[containerClass ? containerClass : '']">
    <a-image
        data-aos="fade-in"
        data-aos-delay="10"
        data-aos-once="true"
        *ngIf="hasBg"
        [bgImageConfig]="bgImage"
        [fixedSize]="false">
        <div
            [ngClass]="{
                overlay: true
            }">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
    </a-image>

    <section
        [ngClass]="{ 'o-cards': true, 'grey-background': greyBackground }"
        *ngIf="!bgImage">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </section>

    <ng-template #content>
        <div>
            <ng-content select="[header]"></ng-content>
            <ng-container *ngIf="!hasNoData">
                <m-loader-container
                    [isPaginatorLoader]="true"
                    [loading]="paginatorLoading"
                    text="common.loader.label">
                    <div
                        class="row"
                        *ngIf="!idCarousel">
                        <ng-container [ngTemplateOutlet]="items"></ng-container>
                    </div>
                </m-loader-container>
            </ng-container>

            <ngb-carousel *ngIf="idCarousel && carousel && carousel.carouselContent">
                <ng-template
                    ngbSlide
                    *ngFor="let item of carousel.carouselContent; let i = index">
                    <div [ngClass]="{ row: true }">
                        <div
                            [ngClass]="[colClass]"
                            *ngFor="let card of item">
                            <m-cards-item
                                [title]="card.title"
                                [desc]="card.desc"
                                [headerTitle]="card.headerTitle"
                                [imgSrc]="card.imgSrc"
                                [mediumSizeImg]="card.mediumSizeImg"
                                [backgroundImgSrc]="card.backgroundImgSrc"
                                [iconSet]="card.iconSet"
                                [iconName]="card.iconName"
                                [iconLeft]="card.iconLeft"
                                [halfTitle]="card.halfTitle"
                                [greyScaleImage]="card.greyScaleImage"
                                [btnTitle]="card.btnTitle"
                                [isCard]="card.isCard"
                                [isCredential]="card.isCredential"
                                [noSideBorders]="card.noSideBorders"
                                [borderBottom]="card.borderBottom"
                                [hasLink]="card.hasLink"
                                [linkLabel]="card.linkLabel"
                                [linkUrl]="card.linkUrl"
                                [hasLines]="card.hasLines"
                                [padding0]="card.padding0"
                                [w50]="card.w50"
                                [w100]="card.w100"
                                [regularSize]="card.regularSize"
                                [noPadding]="card.noPadding"
                                [hasModal]="card.hasModal"
                                [modalId]="card.modalId"
                                [isClickable]="card.isClickable"
                                [callback]="card.callback">
                                <ng-container avatar>
                                    <m-avatar
                                        *ngIf="card.avatarTitle"
                                        class="avatar"
                                        [componentClass]="card.avatarComponentClass"
                                        [imgSrc]="card.avatarImgSrc"
                                        [title]="card.avatarTitle"
                                        [desc]="card.avatarDesc"
                                        [textHasBreak]="card.avatarTextHasBreak"></m-avatar>
                                </ng-container>
                            </m-cards-item>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>

            <div
                class="more"
                data-aos-once="true"
                data-aos="fade-up"
                data-aos-delay="10"
                *ngIf="btnMoreLabel">
                <a-button
                    [buttonStyle]="btnMoreCss"
                    (onClick)="click()"
                    type="button">
                    <span [innerHtml]="getBtnMoreLabel"></span>
                </a-button>
            </div>

            <ng-content select="[footer]"></ng-content>

            <m-no-data-content
                content="common.no.content.data.label"
                *ngIf="hasNoData"></m-no-data-content>
        </div>
    </ng-template>

    <ng-template #items>
        <ng-content select="[items]"></ng-content>
    </ng-template>
</div>
