import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CtaBannerComponent } from './m-cta-banner.component';
import { RouterModule } from '@angular/router';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { TitleModule } from '../../atoms/a-title/a-title.module';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';
import { ButtonModule } from '../../atoms/a-button/a-button.module';

@NgModule({
    declarations: [CtaBannerComponent],
    imports: [
        CommonModule,
        TranslateModule,
        IconModule,
        ImageModule,
        TitleModule,
        ParagraphModule,
        ButtonModule,
        RouterModule,
    ],
    exports: [CtaBannerComponent],
})
export class CtaBannerModule {}
