import { OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformService } from '../utils/platform/platform.service';
import { AppInjector } from '../../app.module';
import { MessageService } from '../utils/message/message.service';

@Component({
    template: '',
})
export abstract class ComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];
    platform: PlatformService;
    messageService: MessageService;

    constructor() {
        this.platform = AppInjector.get(PlatformService);
        this.messageService = AppInjector.get(MessageService);
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
            this.subscriptions = [];
        }
    }
}
