<div
    class="m-no-data-content"
    [ngClass]="[containerClass ? containerClass : '']">
    <div class="icon-wrapper">
        <a-icon
            [iconSet]="true"
            source="fas fa-exclamation-circle"></a-icon>
        <a-paragraph
            [medium]="true"
            [bold]="true"
            [content]="content | translate"></a-paragraph>
    </div>
</div>
